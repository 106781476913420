import { memo, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { CaptureContext } from '../../../context/CaptureContext';
// import { VideoCaptureReducer, initialState } from '../../reducer/VideoCaptureReducer';
import VirtualJoystick from './VirtualJoystick';
import { isMobile } from 'react-device-detect';
import { fontSize, outLineNone } from '../../../styledComponents/btnStyle';
import styled, { css } from 'styled-components';
import { PostureDetectContext } from '../../../context/PostureDetectContext';
import { VoiceControlContext } from '../../../context/VoiceControlContext';
import { SwitchDetectContext } from '../../../context/SwitchDetectContext';
import ModePicker from '../ModePicker';
import { FaceDetectContext } from '../../../context/FaceDetectContext';
// import { messageShow } from '../messageShow';
// import { turnOffVideoStream } from './StreamControl';

const CaptureBtn = () => {
    // const [streamState, dispatch] = useReducer(VideoCaptureReducer, initialState)
    const {
        cameraState, btnScreenShotRef,
        mobileLens, mode, 
        capturedImg, reCapture, 
        setReCapture,imgQuality, 
        setImgQuality,imgTaking, 
        setChooseImg,
        videoRef,canvasRef,canvasEffectRef,
        getVideoStream, turnOffVideoStream,
        handleCaptureImg, recaptureImg,
        getVideoRef, turnOffVideoRef
    } = useContext(CaptureContext);

    const {
        detectFace,loadFaceModels, 
        detectFacesInVideo, stopDetectingFaces
    } = useContext(FaceDetectContext)

    const {
        smileDetect,postureDetect, visualEffect
    } = useContext(SwitchDetectContext)

    const {
        handleMaker,
        postureModels,detect, stopDetection
    } = useContext(PostureDetectContext)

    const {
        voiceVolume
    } = useContext(VoiceControlContext)

    const { t, i18n } = useTranslation();

    const category = {
        quality: i18n.language === "zh" ? '品質' : 'Quality',
        delay: i18n.language === "zh" ? '延遲' : 'Delay'
    }

    const joyStickData = {
        center:{
            name:'',
            fn: () => {
                handleCaptureImg(0, mode, visualEffect)
            }
        },
        top:{
            category: category.quality,
            name: () => {
                const qualityNames = {
                    en: ['lower', 'general', 'higher'],
                    zh: ['低畫質', '普通', '高畫質']
                };
                const quality = i18n.language === "zh"? qualityNames.zh : qualityNames.en;
                if(mode !== 'general'){// 當使用非一班拍照模式不能修改畫質，會有尺寸大小問題
                    return quality[1];
                } else {
                    return quality[qualityNames.en.indexOf(imgQuality)];
                }
                
            }           

            ,
            fn:() => {
                const quality = ['lower', 'general', 'higher'];
                if(mode !== 'general') {// 當使用非一班拍照模式不能修改畫質，會有尺寸大小問題
                    setImgQuality(quality[1])
                } else {
                    let index = quality.indexOf(imgQuality) + 1;
                    if(index > quality.length - 1) {
                        index = 0
                    }
                    setImgQuality(quality[index])
                }
            }
        },
        right:{
            category: category.delay,
            name:'3s',
            fn:() => {
                // console.log('E')
                handleCaptureImg(3, mode, visualEffect)
            }
        },
        down:{
            category: category.delay,
            name:'5s',
            fn:() => {
                // console.log('S')
                handleCaptureImg(5, mode, visualEffect)
            }
        },
        left:{
            category: category.delay,
            name:'10s',
            fn:() => {
                // console.log('W')
                handleCaptureImg(10, mode, visualEffect)
            }
        }
    }

    useEffect(() => {
        if(detectFace) {
            Promise.resolve()
            .then(() => {
                loadFaceModels()
                return Promise.resolve()
            })
        }
    }, [detectFace])

    useEffect(() => {
        if ( !reCapture && cameraState && imgTaking && smileDetect){
            // console.log('開始偵測')
            if(isMobile) {
                // detectFacesInVideo(canvasRef.current.height, canvasRef.current.width) //置入順序必須相反，讓相機偵測方框能正常顯示
                detectFacesInVideo(540, 960) //置入順序必須相反，讓相機偵測方框能正常顯示
            } else {
                // detectFacesInVideo(canvasRef.current.width, canvasRef.current.height)
                detectFacesInVideo(960, 540)
                // console.log('電腦')
            }
            // loadHandModels()
        } else {
            // console.log('停止')
            stopDetectingFaces(reCapture)
        }
    }, [cameraState, reCapture, imgTaking, canvasRef, isMobile, smileDetect])

    

    useEffect(() => {
        if(!reCapture && postureModels && postureDetect && videoRef.current && cameraState && handleMaker && imgTaking) {
            const {handMarker, gestureRecognizer, faceMarker} = handleMaker;
            detect(videoRef, handMarker, gestureRecognizer, faceMarker)
        } else {
            // console.log('執行')
            stopDetection(reCapture, canvasRef)
        }
    }, [cameraState, reCapture, imgTaking, postureModels, videoRef.current, handleMaker, postureDetect, canvasRef , imgTaking])

    return (
        <CaptureBtnArea style={{visibility: imgTaking?'':'hidden'}}>
                {/* 相機啟動切換 */}
                <div style={{flex:1, display:'flex',justifyContent:'center', alignItems:'center'}}>
                    { !cameraState?
                        (<BtnFunction ref={getVideoRef} onClick={() => getVideoStream(cameraState, mobileLens.LensState)}>{t("videoCapture.enable")}</BtnFunction>):
                        (<BtnFunction ref={turnOffVideoRef} onClick={() => turnOffVideoStream()} disabled={!imgTaking? true:false}>{t("videoCapture.disable")}</BtnFunction>)
                    }
                </div>
                {/* 拍照按鈕 */}
                <div style={{flex:1, display:'flex',justifyContent:'center', alignItems:'center', height:'164px'}}>
                    {   reCapture?
                        <BtnScreenshot ref={btnScreenShotRef} $voiceVolume={voiceVolume} $i18n={i18n} onClick={() => 
                            {
                                if (!imgTaking) return;
                                recaptureImg(cameraState, videoRef, i18n.language);
                            }
                        }>
                            <span style={{margin:'auto'}}>
                                {t("videoCapture.recapture")}
                            </span>
                            </BtnScreenshot>:
                        <VirtualJoystick data={joyStickData} />
                    }
                </div>
                <div style={{flex:1, display:'flex', flexDirection:'column'}}>
                { isMobile ?
                    <div>
                        <div style={{margin:'auto',width:'50px', aspectRatio:'1', border:'1px solid #ffffff30', overflow:'hidden'}}>
                            { capturedImg[0] && 
                                <img src={capturedImg[0]} alt="" style={{width:'100%', height:'100%', objectFit:'cover'}} onClick={() => {
                                    setChooseImg(0);
                                    if(reCapture) return ;
                                    setReCapture(true);
                                }}/>
                            }
                        </div> 
                        <ModePicker></ModePicker>
                    </div>
                    :
                        <ModePicker></ModePicker>
                }
                </div>
            </CaptureBtnArea>
    )
}

export default CaptureBtn;

const CaptureBtnArea = styled.div.attrs({className: 'CaptureBtnArea'})`
    /* position:${isMobile ? 'absolute' : 'relative'}; */
    /* position: fixed; */
    /* padding-block: 2rem; */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 640px;
    bottom:${isMobile ? '3rem' : '3rem'};
    /* z-index: 3; */
    z-index: 12;
`;

const BtnFunction = styled.button.attrs({className: 'BtnFunction'})`
    color: ${props => props.theme.btnCaptureText ? `${props.theme.btnCaptureText}`: `${props.theme.btnCaptureText}`} !important;
    position: relative;
    display: flex;
    justify-content: center;
    background: transparent;
    align-items: center;
    font-weight: bolder;
    /* font-size:large; */
    ${fontSize}
    width: 4rem;
    height: 4rem;
    margin-inline: 0.5rem;
    border-radius: 50%;
    border: none;
    white-space: break-spaces;
    box-shadow: ${props => `0 0 0 5px ${props.theme.textColor}` };
    cursor: pointer;
    ${outLineNone};
    &:hover {
        ${!isMobile && css`
            & {
                box-shadow: ${props => `0 0 0 5px ${props.theme.textColorHover}` };
            }
        `}
    }

    &:active {
        ${isMobile && css`
            & {
                box-shadow: ${props => `0 0 0 5px ${props.theme.textColorHover}` };
            }
        `}
    }
`

const BtnScreenshot = styled.button.attrs({className: 'BtnScreenshot'})`
    position: relative;
    display: flex;
    width: 100px;
    height: 100px;
    background: ${props => props.theme.blurBg};
    /* font-size: large; */
    font-size: ${props => props.$i18n.language === "zh"? 'large' : 'small'};
    font-weight: bold;
    border: 0;
    border-radius: 50%;
    /* box-shadow: ${props => `0 0 0 8px ${props.theme.boxShadow}` }; */
    border:${props => `8px solid ${props.theme.boxShadow}` };
    margin-block: 2rem;
    cursor: pointer;
    ${outLineNone};
    &:hover {
        ${!isMobile && css`
            /* box-shadow: ${props => `0 0 0px 8px ${props.theme.boxShadowHover}` }; */
            border:${props => `8px solid ${props.theme.boxShadowHover}` };
        `};
    }
    &:active {
        ${isMobile && css`
            /* box-shadow: ${props => `0 0 0px 8px ${props.theme.boxShadowHover}` }; */
            border:${props => `8px solid ${props.theme.boxShadowHover}` };
        `}
    }

    &::before {
        content:"";
        position: absolute;
        max-width: 120%;
        max-height: 120%;
        width: ${props => (1 + props.$voiceVolume) * 100+ '%'};
        height: ${props => (1 + props.$voiceVolume) * 100+ '%'};
        left:50%;
        top: 50%;
        border-radius: 50%;
        transform:translate(-50%, -50%);
        /* box-shadow: ${props => `0 0 0 1px ${props.theme.boxShadow}` }; */
        border:${props => `1px solid ${props.theme.boxShadow}` };
        transition: width 0.2s, height 0.2s;
    }
`;