import React, { useEffect, useRef, useState, useReducer, useMemo, useContext, memo } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { VoiceControlContext } from '../../../context/VoiceControlContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { SwitchDetectContext } from '../../../context/SwitchDetectContext';

const VirtualJoystick = ({data}) => {
    const {
        voiceVolume
    } = useContext(VoiceControlContext)
    const {
        voiceDetect
    } = useContext(SwitchDetectContext)
    const {top, right, down, left, center } = data;
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [category, setCategory] = useState('');
    const containerRef = useRef(null);
    const joystickRef = useRef(null);
    const [micIconShow, setMicIconShow] = useState(true) // 用來顯示joystick內mic圖示，當任何點擊操作都會隱藏該圖示
    const stateChange = (set, state) => {
        set(state)
    }

    const handleTouchStart = (event) => {
        stateChange(setMicIconShow, false);
        if (!isMobile) return;
        const sticker = joystickRef.current;
        if (!sticker) return;
        if (event.target === sticker) {
            setIsDragging(true);
            handleTouchMove(event);
        }
    };

    const handleTouchMove = (event) => {
        if (!isDragging) return;
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const touch = event.touches[0];
        const offsetX = touch.clientX - centerX;
        const offsetY = touch.clientY - centerY;
        const distance = Math.sqrt(offsetX * offsetX + offsetY * offsetY);
        let angle = Math.atan2(offsetY, offsetX);
        const maxDistance = rect.width / 2;
        const clampedDistance = Math.min(distance, maxDistance);
        const clampedX = Math.cos(angle) * clampedDistance;
        const clampedY = Math.sin(angle) * clampedDistance;
        setPosition({ x: clampedX, y: clampedY });
        let positiveAngle = angle >= 0 ? angle : angle + 2 * Math.PI;
        if(distance >= maxDistance) { // 按壓住拖曳時會顯示類別
            joystickRef.current.style.background = '#00967bc4'
            if (positiveAngle >= 15/8 * Math.PI || positiveAngle < 1/8 * Math.PI) {
                //E
                setCategory(right.category)
            } else if (positiveAngle >= 1/8 * Math.PI && positiveAngle < 3/8 * Math.PI) {
                //SE
            } else if (positiveAngle >= 3/8 * Math.PI && positiveAngle < 5/8 * Math.PI) {
                //S
                setCategory(down.category)
            } else if (positiveAngle >= 5/8 * Math.PI && positiveAngle < 7/8 * Math.PI) {
                //SW
            } else if (positiveAngle >= 7/8 * Math.PI && positiveAngle < 9/8 * Math.PI) {
                //W
                setCategory(left.category)
            } else if (positiveAngle >= 9/8 * Math.PI && positiveAngle < 11/8 * Math.PI) {
                //NW
            } else if (positiveAngle >= 11/8 * Math.PI && positiveAngle < 13/8 * Math.PI) {
                //N
                setCategory(top.category)
            } else if (positiveAngle >= 13/8 * Math.PI && positiveAngle < 15/8 * Math.PI) {
                //NE
            }
            // console.log(direction)
        } else {
            joystickRef.current.style.background = ''
            setCategory('')
        }
}

    const handleTouchEnd = (event) => {
        stateChange(setMicIconShow, true);
        if(!isMobile) return;
        setIsDragging(false);
        if(isDragging){
            if (event.changedTouches && event.changedTouches.length > 0) {
                const touch = event.changedTouches[0];
                const container = event.currentTarget;
                const rect = container.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;
                const offsetX = touch.clientX - centerX;
                const offsetY = touch.clientY - centerY;
                const distance = Math.sqrt(offsetX * offsetX + offsetY * offsetY);
                let angle = Math.atan2(offsetY, offsetX);
                const maxDistance = rect.width / 2;
                let positiveAngle = angle >= 0 ? angle : angle + 2 * Math.PI;
                if(distance >= maxDistance) {
                    if (positiveAngle >= 15/8 * Math.PI || positiveAngle < 1/8 * Math.PI) {
                        //E
                        right.fn()
                    } else if (positiveAngle >= 1/8 * Math.PI && positiveAngle < 3/8 * Math.PI) {
                        //SE
                    } else if (positiveAngle >= 3/8 * Math.PI && positiveAngle < 5/8 * Math.PI) {
                        //S
                        down.fn()
                    } else if (positiveAngle >= 5/8 * Math.PI && positiveAngle < 7/8 * Math.PI) {
                        //SW
                    } else if (positiveAngle >= 7/8 * Math.PI && positiveAngle < 9/8 * Math.PI) {
                        //W
                        left.fn()
                    } else if (positiveAngle >= 9/8 * Math.PI && positiveAngle < 11/8 * Math.PI) {
                        //NW
                    } else if (positiveAngle >= 11/8 * Math.PI && positiveAngle < 13/8 * Math.PI) {
                        //N
                        top.fn()
                    } else if (positiveAngle >= 13/8 * Math.PI && positiveAngle < 15/8 * Math.PI) {
                        //NE
                    }
                } else {
                    if(position.x === 0 && position.y ===0){
                        center.fn();
                    }
                }
                setPosition({ x: 0, y: 0 });
                setCategory('')
            }
        }
        joystickRef.current.style.background = ''
    }

    const handleMouseStart = (event) => {
        stateChange(setMicIconShow, false);
        if (isMobile) return;
        const sticker = joystickRef.current;
        if (!sticker) return;
    
        if (event.target === joystickRef.current) {
            setIsDragging(true);
            handleMouseMove(event);
            // document.removeEventListener('mouseup', handleGlobalMouseEnd);
            // document.addEventListener('mouseup', handleGlobalMouseEnd);
            // return () => {
            // };
        }
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;
        const rect = event.currentTarget.getBoundingClientRect(); // 取得事件目標（搖桿）的邊界矩形，包含其位置和尺寸資訊
        const centerX = rect.left + rect.width / 2; // 計算搖桿的水平中心點
        const centerY = rect.top + rect.height / 2;
        const clientX = event.clientX || (event.touches && event.touches[0].clientX); // 獲取滑鼠或觸摸點的 X 座標，如果是觸摸事件，則從觸摸點中提取。
        const clientY = event.clientY || (event.touches && event.touches[0].clientY);
        const offsetX = clientX - centerX; // 計算從中心點到滑鼠或觸摸點的水平偏移量。
        const offsetY = clientY - centerY;
        const distance = Math.sqrt(offsetX * offsetX + offsetY * offsetY);// 計算滑鼠或觸摸點與中心點之間的距離
        const maxDistance = rect.width / 2; // 定義搖桿允許的最大距離，即搖桿半徑
        const clampedDistance = Math.min(distance, maxDistance); // 將計算出的距離限制在最大距離之內
        const clampedX = Math.cos(Math.atan2(offsetY, offsetX)) * clampedDistance; // 根據角度計算限制後的 X 座標位置
        const clampedY = Math.sin(Math.atan2(offsetY, offsetX)) * clampedDistance;
        setPosition({ x: clampedX, y: clampedY });
        let angle = Math.atan2(offsetY, offsetX); // 計算從中心點到滑鼠或觸摸點的角度（弧度）。
        let positiveAngle = angle >= 0 ? angle : angle + 2 * Math.PI;
        if(distance >= maxDistance) {
            joystickRef.current.style.background = '#00967bc4'
            if (positiveAngle >= 15/8 * Math.PI || positiveAngle < 1/8 * Math.PI) {
                //E
                setCategory(right.category)
            } else if (positiveAngle >= 1/8 * Math.PI && positiveAngle < 3/8 * Math.PI) {
                //SE
            } else if (positiveAngle >= 3/8 * Math.PI && positiveAngle < 5/8 * Math.PI) {
                //S
                setCategory(down.category)
            } else if (positiveAngle >= 5/8 * Math.PI && positiveAngle < 7/8 * Math.PI) {
                //SW
            } else if (positiveAngle >= 7/8 * Math.PI && positiveAngle < 9/8 * Math.PI) {
                //W
                setCategory(left.category)
            } else if (positiveAngle >= 9/8 * Math.PI && positiveAngle < 11/8 * Math.PI) {
                //NW
            } else if (positiveAngle >= 11/8 * Math.PI && positiveAngle < 13/8 * Math.PI) {
                //N
                setCategory(top.category)
            } else if (positiveAngle >= 13/8 * Math.PI && positiveAngle < 15/8 * Math.PI) {
                //NE
            }
        } else {
            joystickRef.current.style.background = ''
            setCategory('')
        }
    };

    const handleMouseEnd = (event) => {
        stateChange(setMicIconShow, true);
        event.stopPropagation(); //避免冒泡觸發兩次導致position.x === 0 && position.y === 0判斷兩次
        const container = containerRef.current;
        const joystick = joystickRef.current;
        if (!container) return; // 容器或搖桿可能尚未設置
    
        const containerRect = container.getBoundingClientRect();
    
        // 取得 container 的邊緣座標
        const containerLeft = containerRect.left;
        const containerTop = containerRect.top;
        const containerRight = containerRect.right;
        const containerBottom = containerRect.bottom;
    
        // const container = containerRef.current;
        const rect = container.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const clientX = event.clientX || (event.touches && event.touches[0].clientX);
        const clientY = event.clientY || (event.touches && event.touches[0].clientY);
        const offsetX = clientX - centerX;
        const offsetY = clientY - centerY;
        // const distance = Math.sqrt(offsetX * offsetX + offsetY * offsetY);
        const maxDistance = rect.width / 2;
        const distance = Math.sqrt(offsetX * offsetX + offsetY * offsetY);
        
        let angle = Math.atan2(offsetY, offsetX);
        let positiveAngle = angle >= 0 ? angle : angle + 2 * Math.PI;

        // 如果滑鼠離開容器但 joystick 的中心仍在容器邊緣，則觸發相應函式
        if(isDragging) {
            if (
                event.clientX < containerLeft ||
                event.clientX > containerRight ||
                event.clientY < containerTop ||
                event.clientY > containerBottom
            ) {
                if(distance >= maxDistance) {
                    if (positiveAngle >= 15/8 * Math.PI || positiveAngle < 1/8 * Math.PI) {
                        //E
                        right.fn()
                    } else if (positiveAngle >= 1/8 * Math.PI && positiveAngle < 3/8 * Math.PI) {
                        //SE
                    } else if (positiveAngle >= 3/8 * Math.PI && positiveAngle < 5/8 * Math.PI) {
                        //S
                        down.fn()
                    } else if (positiveAngle >= 5/8 * Math.PI && positiveAngle < 7/8 * Math.PI) {
                        //SW
                    } else if (positiveAngle >= 7/8 * Math.PI && positiveAngle < 9/8 * Math.PI) {
                        //W
                        left.fn()
                    } else if (positiveAngle >= 9/8 * Math.PI && positiveAngle < 11/8 * Math.PI) {
                        //NW
                    } else if (positiveAngle >= 11/8 * Math.PI && positiveAngle < 13/8 * Math.PI) {
                        //N
                        top.fn()
                    } else if (positiveAngle >= 13/8 * Math.PI && positiveAngle < 15/8 * Math.PI) {
                        //NE
                    }
                } 
            }else {
                if(Math.abs(position.x) <= 10 && Math.abs(position.y) <= 10){// 加大偏差值讓不是完全點擊在中心也能觸發
                    center.fn()
                }
            }
        }
        
    
        setPosition({ x: 0, y: 0 });
        setCategory('');
        setIsDragging(false);
        joystick.style.background = '';
    };
    

    const handleGlobalMouseEnd = (event) => {
        stateChange(setMicIconShow, true);
        event.stopPropagation(); 
        if (isMobile) return;
        const container = containerRef.current;
        const joystick = joystickRef.current;
        if (!container) return; 
        const rect = container.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const clientX = event.clientX || (event.touches && event.touches[0].clientX);
        const clientY = event.clientY || (event.touches && event.touches[0].clientY);
        const offsetX = clientX - centerX;
        const offsetY = clientY - centerY;
        const maxDistance = rect.width / 2;
        const distance = Math.sqrt(offsetX * offsetX + offsetY * offsetY);

        let angle = Math.atan2(offsetY, offsetX);
        let positiveAngle = angle >= 0 ? angle : angle + 2 * Math.PI;
        if(distance >= maxDistance) {
            if (positiveAngle >= 15/8 * Math.PI || positiveAngle < 1/8 * Math.PI) {
                //E
                right.fn()
            } else if (positiveAngle >= 1/8 * Math.PI && positiveAngle < 3/8 * Math.PI) {
                //SE
            } else if (positiveAngle >= 3/8 * Math.PI && positiveAngle < 5/8 * Math.PI) {
                //S
                down.fn()
            } else if (positiveAngle >= 5/8 * Math.PI && positiveAngle < 7/8 * Math.PI) {
                //SW
            } else if (positiveAngle >= 7/8 * Math.PI && positiveAngle < 9/8 * Math.PI) {
                //W
                left.fn()
            } else if (positiveAngle >= 9/8 * Math.PI && positiveAngle < 11/8 * Math.PI) {
                //NW
            } else if (positiveAngle >= 11/8 * Math.PI && positiveAngle < 13/8 * Math.PI) {
                //N
                top.fn()
            } else if (positiveAngle >= 13/8 * Math.PI && positiveAngle < 15/8 * Math.PI) {
                //NE
            }
        } else {
            if(position.x === 0 && position.y === 0){
                center.fn()
            }
        }
        setPosition({ x: 0, y: 0 });
        setCategory('');
        setIsDragging(false);
        joystick.style.background = '';
    };
    useEffect(() => {
        if(isDragging) {
            document.addEventListener('mouseup', handleGlobalMouseEnd);
        }
        return () => {
            document.removeEventListener('mouseup', handleGlobalMouseEnd);
        };
        
    }, [isDragging]);
    return (
            <JoystickContainer
                ref={containerRef}
                $voiceVolume={voiceVolume}
                onMouseDown={handleMouseStart}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseEnd}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <span style={{position:'absolute', top:'-2rem', left:'50%', transform:'translateX(-50%)'}}>{typeof top.name === 'function'? top.name() : top.name}</span>
                <span style={{position:'absolute', top:'50%', right:'-2rem', transform:'translateY(-50%)'}}>{typeof right.name === 'function'?right.name() : right.name}</span>
                <span style={{position:'absolute', bottom:'-2rem', left:'50%', transform:'translateX(-50%)'}}>{typeof down.name === 'function'?down.name() : down.name}</span>
                <span style={{position:'absolute', top:'50%', left:'-2rem', transform:'translateY(-50%)'}}>{typeof left.name === 'function'?left.name() : left.name}</span>
                <JoystickHandle style={{ left: position.x + 50, top: position.y + 50 , display:'flex', justifyContent:'center', alignItems:'center'}} ref={joystickRef}>
                    {category}
                    { isMobile && voiceDetect && micIconShow &&
                        <FontAwesomeIcon icon={faMicrophone} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', opacity:'0.3', fontSize:'xx-large', pointerEvents:'none'}} />
                    }
                    </JoystickHandle>
            </JoystickContainer>
    );
};

export default memo(VirtualJoystick);

const JoystickContainer = styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    /* box-shadow: ${props => `0 0 0 3px ${props.theme.boxShadow}` }; */
    border-radius: 50%;
    border:${props => `3px solid ${props.theme.boxShadow}` };
    &::before {
        content:"";
        position: absolute;
        max-width: 110%;
        max-height: 110%;
        width: ${props => 100 +(  props.$voiceVolume) * 100 + '%'};
        height: ${props => 100 +( props.$voiceVolume) * 100 + '%'};
        left:50%;
        top: 50%;
        border-radius: 50%;
        transform:translate(-50%, -50%);
        /* box-shadow: ${props => `0 0 0 1px ${props.theme.boxShadow}` }; */
        border:${props => `1px solid ${props.theme.boxShadow}` };
        transition: width 0.2s, height 0.2s;
    }
`;

const JoystickHandle = styled.div`
    position: absolute;
    display: flex;
    width: 70px;
    height: 70px;
    background-color: ${props => `${props.theme.boxShadow}` };
    border-radius: 50%;
    transform: translate(-50%, -50%);
    /* pointer-events: none; */
    cursor: pointer;
`;