// 禁止手機滑動預設行為
// 使用: 直接執行function
export const preventTouchMove = () => {
    const preventScroll = (event) => {
        event.preventDefault();
    };
    document.addEventListener('touchmove', preventScroll, { passive: false });
    return () => {
        document.removeEventListener('touchmove', preventScroll);
    }
}