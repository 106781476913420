import { Outlet, useNavigate, Link } from 'react-router-dom';
import Navbar from '../components/version/Navbar';
import Sidebar from '../components/version/Sidebar';
import styled from 'styled-components';
import Declaration from './Declaration';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { shareSidebarValue, sidebarSwitchFn } from '../redux/slice/sidebarSwitchSlice';

function FrontLayout() {
    const [announce, setAnnounce] = useState(false);
    const dispatch = useDispatch();

    return (
        <>
            {announce && <Declaration dataSet={setAnnounce} data={announce}/>}
            <MobileHamburger onClick={() => dispatch(sidebarSwitchFn('switch'))}>
                <FontAwesomeIcon style={{fontSize:'1.5rem'}} icon={faBars}/>
            </MobileHamburger>
            <Navbar/>
            <MainArea>
                <Sidebar dataSet={setAnnounce} data={announce}/>
                <ContentArea>
                    <Outlet/>
                </ContentArea>
                {/* <ScrollToTop></ScrollToTop> */}
            </MainArea>
        </>
    )
}

export default FrontLayout;
const MobileHamburger = styled.nav.attrs({className:'MobileHamburger'})`
    position:absolute;
    top: 2rem;
    left: 1rem;
    display: none;
    margin-right: 1rem;
    @media (max-width: 992px) {
        display: block;
        z-index: 13;
    }
`;

const MainArea = styled.div`
    display: flex;
    position: relative;
    height: calc(100vh - 4.5rem);
    @media (max-width: 992px) {
            height: 100%;
    }
`;

const ContentArea = styled.div`
    overflow: hidden;
    /* height: calc(100vh - 4rem); */
    height: 100%;
    width: 100%;
    /* height: 100%; */
`;

