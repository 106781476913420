import React, { createContext, useState} from 'react';
const SwitchDetectContext = createContext();


// 用來管理設定按鈕的開關狀態
const SwitchDetectProvider = ({children}) => {
    const [photoSound, setPhotoSound] = useState(true); // 拍照音效控制
    const [smileDetect, setSmileDetect] = useState(false); // 微笑偵測
    const [postureDetect, setPostureDetect] = useState(false); // 手勢偵測啟動
    const [voiceDetect, setVoiceDetect] = useState(false); // 提供給控制開關
    const [visualEffect, setVisualEffect] = useState(''); // 特效控制
    return (
        <SwitchDetectContext.Provider 
            value={{
                photoSound, setPhotoSound,
                smileDetect, setSmileDetect,
                postureDetect, setPostureDetect,
                voiceDetect, setVoiceDetect,
                visualEffect, setVisualEffect
            }}>
        {children}
        </SwitchDetectContext.Provider>
    );
}
export { SwitchDetectContext,  SwitchDetectProvider}