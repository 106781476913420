import React, { createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import { SwitchDetectContext } from './SwitchDetectContext';
import { CaptureContext } from './CaptureContext';
import { isMobile } from 'react-device-detect';
const CanvasEffectContext = createContext();


// 用來管理canvas特殊效果
const CanvasEffectProvider = ({children}) => {
    const {
        visualEffect
    } = useContext(SwitchDetectContext)
    const {
        cameraState,reCapture,videoRef,
        canvasRef,canvasEffectRef,mobileLens
    } = useContext(CaptureContext)

    // ===================== 魚眼/貓眼===================
    const effectInterval = useRef(null);
    const rangeRef = useRef(null);
    const [effectIntensity, setEffectIntensity ] = useState(50)
    const fisheye = (canvas, effectIntensity, data, ctx) => {
        const width = canvas.width;
        const height = canvas.height;
        const centerX = width / 2;
        const centerY = height / 2;
        const radius = Math.min(centerX, centerY);
        const strength = effectIntensity / 100; // 调整鱼眼效果的强度

        const newImageData = ctx.createImageData(width, height);
        const newData = newImageData.data;
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const dx = x - centerX;
                const dy = y - centerY;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < radius) {
                    const factor = (1 - strength) + strength * Math.sqrt(1 - (distance / radius) * (distance / radius));
                    const srcX = Math.floor(centerX + factor * dx);
                    const srcY = Math.floor(centerY + factor * dy);

                    if (srcX >= 0 && srcX < width && srcY >= 0 && srcY < height) {
                        const srcIndex = (srcY * width + srcX) * 4;
                        const dstIndex = (y * width + x) * 4;

                        newData[dstIndex] = data[srcIndex];       // Red
                        newData[dstIndex + 1] = data[srcIndex + 1]; // Green
                        newData[dstIndex + 2] = data[srcIndex + 2]; // Blue
                        newData[dstIndex + 3] = data[srcIndex + 3]; // Alpha
                    }
                }
            }
        }
        ctx.putImageData(newImageData, 0, 0);
    }

    const catEye = (canvas, effectIntensity, data, ctx) => {
        const width = canvas.width;
        const height = canvas.height;
        const centerX = width / 2;
        const centerY = height / 2;
        const radius = Math.min(centerX, centerY);
        const strength = effectIntensity / 100; // 调整鱼眼效果的强度
        const newImageData = ctx.createImageData(width, height);
        const newData = newImageData.data;
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const dx = x - centerX;
                const dy = y - centerY;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < radius) {
                    const factor = (1 - strength) + strength * Math.sqrt(1 * 0.1 + (distance / radius) * (distance / radius) * 0.2);
                    const srcX = Math.floor(centerX + factor * dx);
                    const srcY = Math.floor(centerY + factor * dy);

                    if (srcX >= 0 && srcX < width && srcY >= 0 && srcY < height) {
                        const srcIndex = (srcY * width + srcX) * 4;
                        const dstIndex = (y * width + x) * 4;
                        newData[dstIndex] = data[srcIndex];       // Red
                        newData[dstIndex + 1] = data[srcIndex + 1]; // Green
                        newData[dstIndex + 2] = data[srcIndex + 2]; // Blue
                        newData[dstIndex + 3] = data[srcIndex + 3]; // Alpha
                    }
                }
            }
        }


        ctx.putImageData(newImageData, 0, 0);
    }

    const [test, setTest] = useState(0)

// ======================= 色調=====================
    const redHueRef = useRef(null);
    const greenHueRef = useRef(null);
    const blueHueRef = useRef(null);
    const brightnessRef = useRef(null);
    const [hueRedValue, setHueRedValue] = useState(0)
    const [hueGreenValue, setHueGreenValue] = useState(0)
    const [hueBlueValue, setHueBlueValue] = useState(0)
    const [brightness, setBrightness] = useState(0)

    const imghue = (data, imageData, ctx, red, green, blue) => {
        for (let i = 0; i < data.length; i += 4) {// 每個像素的顏色都是由4個分量組成
            data[i] = Math.min(255, Math.max(0, data[i] + red));       // 增加红色分量
            data[i + 1] = Math.min(255, Math.max(0, data[i + 1] + green)); // 增加綠色分量
            data[i + 2] = Math.min(255, Math.max(0, data[i + 2] + blue));  // 增加藍色分量
        }
        ctx.putImageData(imageData, 0, 0);
    }

    const brightnessHue = (data, imageData, ctx, brightness) => {
        for (let i = 0; i < data.length; i += 4) {// 每個像素的顏色都是由4個分量組成
            data[i] = Math.min(255, Math.max(0, data[i] + brightness));       // 增加红色分量
            data[i + 1] = Math.min(255, Math.max(0, data[i + 1] + brightness)); // 增加綠色分量
            data[i + 2] = Math.min(255, Math.max(0, data[i + 2] + brightness));  // 增加藍色分量
        }
        ctx.putImageData(imageData, 0, 0);
    }

    const hueReversal = (data, imageData, ctx) => {
        for (let i = 0; i < data.length; i += 4) {// 每個像素的顏色都是由4個分量組成
            //開啟會反轉顏色
            let red = data[i];         // red
            let green = data[i + 1];   // green
            let blue = data[i + 2];    // blue
            // let alpha = data[i + 3];   // opacity
            data[i] = 255 - red;       // 反轉red
            data[i + 1] = 255 - green; // 反轉green
            data[i + 2] = 255 - blue;  // 反轉blue
            // data[i + 3] = 255 - alpha;  // 反轉opacity
        }
        ctx.putImageData(imageData, 0, 0);
    }

    // let mobileLens = mobileLens;
    const drawEffect = useCallback(() => {
        const video = videoRef.current;
        const canvas = canvasEffectRef.current;
        const ctx = canvas.getContext('2d');
        try {
            if (video && canvas.width >= 0 && ctx && canvas) {
                video.style.opacity = 0;
                // console.log('在跑嗎?')
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                if(isMobile && mobileLens.LensState){
                    ctx.scale(1, 1);
                    ctx.translate(0, 0);
                } else {
                    ctx.scale(-1, 1); // 左右相反
                    ctx.translate(-canvas.width, 0); //調整畫布起始點
                }
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                // Apply fisheye effect (simple example, adjust as needed)
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                const data = imageData.data;
                
                // Modify data to create fisheye effect (implement your own logic here)
                // Example: invert colors as a placeholder for effect
                switch (visualEffect) {
                    case 'fishEye':
                        // console.log("fishEye")
                        fisheye(canvas, effectIntensity, data, ctx);
                        break;
                    case 'catEye':
                        // console.log("catEy")
                        catEye(canvas, effectIntensity, data, ctx);
                        break;
                    case 'imgHue':
                        brightnessHue(data, imageData, ctx, brightness)
                        imghue(data, imageData, ctx, hueRedValue, hueGreenValue, hueBlueValue)
                        break;
                    case 'hueReversal':
                        hueReversal(data, imageData, ctx)
                        break;
                    
                    default:
                        console.warn('Unknown effect mode:', effectIntensity.mode);
                }
            }
        } catch (error) {
            // console.log(error)
        }
    }, [videoRef.current, canvasEffectRef.current, effectIntensity, visualEffect, hueRedValue, hueGreenValue, hueBlueValue, brightness])

    useEffect(() => {
        if(!visualEffect) {
            const canvas = canvasEffectRef.current;
            if(canvasEffectRef.current){
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
            if(videoRef.current) {
                videoRef.current.style.opacity = '1';
            }
            return;
        }

        
        if(cameraState && visualEffect && canvasEffectRef.current && videoRef.current) {
            if(isMobile) {
                effectInterval.current= setInterval(drawEffect, 0); // Adjust interval as needed
            } else {
                effectInterval.current= setInterval(drawEffect, 25); // Adjust interval as needed
            }
            // console.log('執行')
            // effectInterval.current = intervalId
        } else {
            return clearInterval(effectInterval.current);
        }
        
    
        return () => clearInterval(effectInterval.current);
    }, [cameraState, videoRef.current, canvasEffectRef.current, visualEffect, reCapture, mobileLens, effectIntensity, hueRedValue, hueGreenValue, hueBlueValue, brightness ]);

    return (
        <CanvasEffectContext.Provider 
            value={{
                effectIntensity, setEffectIntensity, rangeRef,
                redHueRef,greenHueRef,blueHueRef,brightnessRef,
                hueRedValue, setHueRedValue,
                hueGreenValue, setHueGreenValue,
                hueBlueValue, setHueBlueValue,
                brightness, setBrightness,
            }}>
        {children}
        </CanvasEffectContext.Provider>
    );
}
export { CanvasEffectContext,  CanvasEffectProvider}