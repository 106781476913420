import { memo, useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconBtn, outLineNone } from '../../../styledComponents/btnStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleHalfStroke, faEye, faFaceSmile, faGear, faHand, faMicrophone, faPalette, faVolumeLow } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { CaptureContext } from '../../../context/CaptureContext';
import { Popover, Switch } from 'antd';
import '../../../css/VideoScreenshot/App.css'
import { SwitchDetectContext } from '../../../context/SwitchDetectContext';
import { ElementToggle } from '../ElementToggle';

const SettingFunctionalBtn = () => {
    const {
        photoSound, setPhotoSound,
        smileDetect, setSmileDetect,
        postureDetect, setPostureDetect,
        voiceDetect, setVoiceDetect,
        visualEffect,setVisualEffect

    } = useContext(SwitchDetectContext)
    const {
        fnBtnShow, setFnBtnShow,
    } = useContext(CaptureContext);


    const { t, i18n } = useTranslation();

    const settingAreaRef = useRef(null);

    const handleToggleBtn = () => {
        setTimeout(() => {
            setFnBtnShow(!fnBtnShow)
        }, 0)
    }

    useEffect(() => {
        if(settingAreaRef.current && fnBtnShow) {
            return ElementToggle(settingAreaRef, () => {
                setFnBtnShow(false)
            })
        }
    }, [fnBtnShow])


    const renderContent = (content) => {
        return content.split('\br').map((line, index) => (
            <p style={{marginBottom:'0.5rem'}} key={index}>{line}</p>
        ));
    };
    return (
        <div style={{position:'fixed', right:'1rem',textAlign:'center', bottom:'50%',transform:'translateY(50%)'}}>
            <SideBtnArea $fnbtnshow={fnBtnShow.toString()} ref={settingAreaRef}>
                <SettingAreaDiv>
                    <ul className='' style={{listStyle:'none', margin: 0, padding: 0, width: '100%'}}>
                        <li>
                            <FontAwesomeIcon icon={faCircleHalfStroke} style={{width:'20px'}}/>
                            <span style={{marginInline:'0.5rem'}}>
                                {t("videoCapture.effect_hueReversal")}
                            </span>
                            <Switch checked={visualEffect === 'hueReversal'} style={{marginLeft:'auto'}} onChange={() => setVisualEffect(prev => {
                                if(prev === 'hueReversal'){
                                    return '';
                                } else {
                                    return 'hueReversal';
                                }
                            })}/>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faPalette} style={{width:'20px'}}/>
                            <span style={{marginInline:'0.5rem'}}>
                                {t("videoCapture.effect_hue")}
                            </span>
                            <Switch checked={visualEffect === 'imgHue'} style={{marginLeft:'auto'}} onChange={() => setVisualEffect(prev => {
                                if(prev === 'imgHue'){
                                    return '';
                                } else {
                                    return 'imgHue';
                                }
                            })}/>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faEye} style={{width:'20px'}}/>
                            <span style={{marginInline:'0.5rem'}}>
                                {t("videoCapture.effect_fishEye")}
                            </span>
                            <Switch checked={visualEffect === 'fishEye'} style={{marginLeft:'auto'}} onChange={() => setVisualEffect(prev => {
                                if(prev === 'fishEye'){
                                    return '';
                                } else {
                                    return 'fishEye';
                                }
                            })}/>
                        </li>
                        <li>
                            {/* <FontAwesomeIcon icon={faMusic}  /> */}
                            <FontAwesomeIcon icon={faEye} style={{width:'20px'}}/>
                            <span style={{marginInline:'0.5rem'}}>
                                {t("videoCapture.effect_catEye")}
                            </span>
                            <Switch checked={ visualEffect === 'catEye'} style={{marginLeft:'auto'}} onChange={() => setVisualEffect(prev =>{
                                if(prev === 'catEye'){
                                    return '';
                                } else {
                                    return 'catEye';
                                }
                            })}/>
                        </li>
                        <hr style={{marginBlock:'1rem'}}/>
                        <li>
                            {/* <FontAwesomeIcon icon={faMusic}  /> */}
                            <FontAwesomeIcon icon={faVolumeLow} style={{width:'20px'}}/>
                                <span style={{marginInline:'0.5rem'}}>
                                    {t("videoCapture.photograghSound")}
                                </span>
                            <Switch checked={photoSound} style={{marginLeft:'auto'}} onChange={() => setPhotoSound(!photoSound)}/>
                        </li>
                        <Popover 
                            content={t("settingBtn.smileDetect")} 
                            title="" trigger="hover" 
                            overlayClassName="custom-popover">
                            <li>
                                <FontAwesomeIcon icon={faFaceSmile} style={{width:'20px'}} />
                                    <span style={{marginInline:'0.5rem'}}>{t("videoCapture.smileDetect")}</span>
                                    <Switch  checked={smileDetect} style={{marginLeft:'auto'}} 
                                        onChange={() => {
                                            setSmileDetect(!smileDetect)
                                            setPostureDetect(false)
                                            setVoiceDetect(false)
                                        }}
                                    />
                            </li>
                        </Popover>
                        <Popover 
                            content={t("settingBtn.postureDetect")} 
                            title="" trigger="hover" 
                            overlayClassName="custom-popover">
                            <li>
                                <FontAwesomeIcon icon={faHand} style={{width:'20px'}} />
                                    <span style={{marginInline:'0.5rem'}}>{t("videoCapture.handDetect")}</span>
                                    <Switch checked={postureDetect} style={{marginLeft:'auto'}} 
                                        onChange={() => {
                                            setSmileDetect(false)
                                            setPostureDetect(!postureDetect)
                                            setVoiceDetect(false)
                                        }}
                                    />
                            </li>
                        </Popover>
                        <Popover 
                            content={renderContent(t("settingBtn.voiceDetect"))} 
                            title="" trigger="hover" 
                            overlayClassName="custom-popover">
                            <li>
                                <FontAwesomeIcon icon={faMicrophone} style={{width:'20px'}} />
                                    <span style={{marginInline:'0.5rem'}}>{t("videoCapture.voiceControl")}</span>
                                    <Switch checked={voiceDetect} style={{marginLeft:'auto'}} 
                                    onChange={() => {
                                        setSmileDetect(false)
                                        setPostureDetect(false)
                                        setVoiceDetect(!voiceDetect)
                                    }}
                                />
                            </li>
                        </Popover>
                    </ul>
                </SettingAreaDiv>
            </SideBtnArea> 
            <IconBtn>
                <FontAwesomeIcon icon={faGear} onClick={handleToggleBtn} />
            </IconBtn>   
        </div>
    )
}

export default memo(SettingFunctionalBtn);

const SideBtnArea = styled.div.attrs({className: 'SideBtnArea'})`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5rem;
    padding: 0.1rem;
    display: ${props => props.$fnbtnshow==='true' ? 'block' : 'none'};
    overflow: hidden;
`
const SettingAreaDiv = styled.div.attrs({className: 'SettingAreaDiv'})`
    width: 12rem;
    /* height: 300px; */
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: ${props => props.theme.blurBg};
    border: 1px solid #a7a7a7;
    backdrop-filter: blur(5px);
    
`

const SideBtnFunction = styled.button.attrs({className: 'SideBtnFunction'})`
    color: ${props => props.theme.btnCaptureText ? `${props.theme.btnCaptureText}`: `${props.theme.btnCaptureText}`} !important;
    /* position: relative; */
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.blurBg};
    align-items: center;
    font-weight: bold;
    font-size:large;
    width: 6rem;
    height: 5rem;
    margin-block: 1rem;
    padding-inline:1rem;
    border-radius: 5px;
    border: none;
    white-space: break-spaces;
    box-shadow: ${props => `0 0 0 1px ${props.theme.textColor}` };
    /* background-color: red !important; */
    backdrop-filter:blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: pointer;
    ${outLineNone};
    &:hover {
        ${!isMobile && css`
            & {
                box-shadow: ${props => `0 0 0 3px ${props.theme.textColorHover}` };
            }
        `}
    }

    &:active {
        ${isMobile && css`
            & {
                box-shadow: ${props => `0 0 0 3px ${props.theme.textColorHover}` };
            }
        `}
    }
`