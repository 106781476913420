import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { SwitchDetectContext } from './SwitchDetectContext';

const CaptureContext = createContext();

const CaptureProvider = ({ children }) => {  // 一定要使用children名稱
    const {
        photoSound, 
        visualEffect
    } = useContext(SwitchDetectContext)
    const { t, i18n } = useTranslation();
    const [cameraState, setCameraState] = useState(null);
    const [sharedValue, setSharedValue] = useState('Default Value');
    const [mobile, setMobile] = useState(null)// 1.判定是不是手機裝置，調用不同版型
    const [cameraSizeRatio, setCameraSizeRatio] = useState({// 2.偵測相機尺寸比例
        ratio: 1.77,
        width: 1920,
        height: 1080
    }); 
    const [mobileLens, setMobileLens] = useState({// 設定手機鏡頭方向 true為背面 false為正面(自拍)
        exist:false,
        LensState:true
    })
    const [mode, setMode] = useState('general');// 拍攝模式
    const [capturedImg, setCapturedImg] = useState([]);// 儲存拍照後的照片
    const [reCapture, setReCapture] = useState(false); // 拍照轉為true後待點擊切換為
    const [imgQuality, setImgQuality] = useState('general'); // select設置品質
    const [imgTaking, setImgTaking] = useState(true); // 拍照後等待顯示完成才允許再次拍照(true為可以拍照)
    const [chooseImg, setChooseImg] = useState(null);
    const [fnBtnShow, setFnBtnShow] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const canvasEffectRef = useRef(null);
    const progressLineRef = useRef(null);

    // 聲控用狀態
    const getVideoRef = useRef(null) 
    const turnOffVideoRef = useRef(null)

    //開啟相機
    const getVideoStream = useCallback(async (cameraState, mobileLens) => {
        // console.log('mobileLens',mobileLens)
        if (cameraState) return;
        try {
            // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            let stream;
            if(isMobile){
                if(mobileLens) { //true為背面 false為正面(自拍)
                    stream =  await navigator.mediaDevices.getUserMedia({ video: {
                    facingMode: "environment",
                        width: { ideal: 1920 },//w 1920, h 1080 在手機會變為直式
                        height: { ideal: 1080 }
                    }});
                } else {
                    stream =  await navigator.mediaDevices.getUserMedia({ video: {
                        facingMode: "user",
                        width: { ideal: 1920 },
                        height: { ideal: 1080 }
                    }});
                }
            } else {
                stream = await navigator.mediaDevices.getUserMedia({ video: {
                    width: { min: 640, ideal: 1280, max: 1920 },
                    height: { min: 480, ideal: 720, max: 1080 },
                }});
            }
            if(stream){
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    // videoRef.current.play()
                }
                setCameraState(() => stream)
            }
            return;
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    }, []);

    // useEffect(() => {
    //     if(reCapture) {
    //         console.log('停止')
    //         clearInterval(fisheyeInterval.current);
    //     }
    // }, [reCapture])
    
    const handleVideoSize = () => {
        // console.log('Video width:', videoRef.current.videoWidth, 'height:', videoRef.current.videoHeight);
    }

    //關閉相機
    const turnOffVideoStream = useCallback(() => {
        try {
            if (!cameraState) return;
            cameraState.getTracks().forEach(track => {
                track.stop();
            });
            if (videoRef.current) {
                videoRef.current.srcObject = null;
                setCameraState(null)
            }
            // data = null;
        } catch (error) {
            console.error('Error turning off camera:', error);
        }
    }, [cameraState, videoRef, setCameraState]);

    //各種訊息顯示
    const messageShow = useCallback((target, zh, en, delayTime, timedUse) => {
        const mediaArea =  document.querySelector(target)
        const message = document.createElement('p');
        message.textContent = i18n.language === 'zh'? zh: en;
        message.style.cssText = `
            position: absolute;
            text-wrap:nowrap;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(130 131 131);
            padding:0.5rem 1rem;
            border-radius:5px;
            z-index:1111;
        `;
        const check = mediaArea.querySelector('p')
        const time = setTimeout(() => { mediaArea.removeChild(mediaArea.querySelector('p'))}, delayTime * 1000)
        if(check && !timedUse){// 若不是計時使用則會清除
            mediaArea.removeChild(check);
            clearTimeout(time);
            // console.log('清除')
        }
        
        if(mediaArea) {
            mediaArea.appendChild(message);
            return time;
        }
        return;
    }, []);

    const soundEffect = useCallback((path) => {
        const audio = new Audio(process.env.PUBLIC_URL + path);
        audio.play();
    }, []);

    //拍照
    const handleCaptureImg = useCallback((delay, mode, visualEffect='') => {
        if (!imgTaking) return;
        if (!cameraState) {
            messageShow('.mediaArea', '請先開啟鏡頭', 'Please turn on the camera lens', 2, false);
            return;
        }
        
        setImgTaking(false);
        let delaySecond;
        if(mode !== 'general'){ // 特殊模式要有0.5秒以上的延遲
            delaySecond = delay + 0.6;// 補上 + 0.6秒避免 臉部或手勢偵測時出現，因偵測延遲而出現裁切的異常發生
        } else {
            delaySecond = delay;
        }
        try {
            if (delay >=0) {
                for(let i = delay; i >= 0 ; i--){
                    const countdown = setTimeout(()=> {
                        if(i !==0) {
                            messageShow('.mediaArea', i.toString(), i.toString(), 1, true);
                        }
                        if(i === 0){
                            if (videoRef.current && canvasRef.current) {
                                const canvas = canvasRef.current;
                                const ctx = canvas.getContext('2d');
                                const effectCanvas = canvasEffectRef.current;
                                const effectCtx = effectCanvas.getContext('2d');
                                const videoWidth = cameraState.getVideoTracks()[0].getSettings().width;
                                const videoHeight = cameraState.getVideoTracks()[0].getSettings().height;
                                const qualityOptions = {
                                    lower: 0.5,
                                    general: 1,
                                    higher: 2
                                };
                                const multiplier = qualityOptions[imgQuality];
                                canvas.width = videoWidth * multiplier; // 設置畫布尺寸 倍數越高解析度越高 檔案越大
                                canvas.height = videoHeight * multiplier; 
                                ctx.save();
                                if(!visualEffect) {
                                    if(isMobile && mobileLens.LensState){// 手機 + 背面
                                        ctx.scale(1, 1);
                                        ctx.translate(0, 0);
                                    } else {
                                        ctx.scale(-1, 1); // 左右相反
                                        ctx.translate(-canvas.width, 0); //調整畫布起始點
                                    }
                                }
                                if(mode === "general"){
                                    // 一般拍照
                                    if(visualEffect){// 有特效就從特效畫布抓取
                                        ctx.drawImage(canvasEffectRef.current, 0, 0, canvas.width, canvas.height);
                                    } else {
                                        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                                    }
                                    ctx.restore();
                                    // console.log(canvas.width, canvas.height)
                                    const imgDataUrl = canvas.toDataURL('image/png');
                                    setReCapture(true);
                                    setCapturedImg([imgDataUrl,...capturedImg]);
                                    saveImage(db, imgDataUrl);
                                    // console.log('儲存照片',[imgDataUrl,...capturedImg])
                                    setTimeout(() => {// 拍照按鈕狀態切換延遲
                                        clearInterval(countdown)
                                        setImgTaking(true)
                                        // alert('完成'+imgTaking? 'true': 'false')
                                        
                                    }, 500)
                                    clearInterval(countdown)
                                    setChooseImg(0)// 設置顯示index為0 的照片
                                    if(photoSound){
                                        soundEffect('/soundEffects/shutterSound.wav')
                                    }
                                } else if(mode === "straight"){
                                    // 上到下畫圖
                                    if(!progressLineRef.current.style) return;
                                    const drawLineY = (y) => {
                                        if (y >= canvas.height) {
                                            ctx.restore();
                                            const imgDataUrl = canvas.toDataURL('image/png');
                                            setReCapture(true);
                                            setCapturedImg([imgDataUrl, ...capturedImg]);
                                            saveImage(db, imgDataUrl);
                                            setTimeout(() => { // 拍照按鈕狀態切換延遲
                                                clearInterval(countdown)
                                                setImgTaking(true)
                                            }, 500);
                                            clearInterval(countdown);
                                            setChooseImg(0); // 設置顯示index為0 的照片
                                            if (photoSound) {
                                                soundEffect('/soundEffects/shutterSound.wav');
                                            }
                                            // 隱藏進度線
                                            if(progressLineRef.current){
                                                progressLineRef.current.style.height='0';
                                                progressLineRef.current.style.width = '0';
                                                progressLineRef.current.style.top = '0';
                                            }
                                            return;
                                        }
                                        if(progressLineRef.current){
                                            progressLineRef.current.style.height='1px';
                                            progressLineRef.current.style.width = '100%';
                                            progressLineRef.current.style.top = `${(y/canvas.height)*100}%`;
                                        }
                                        if(isMobile){
                                            if(visualEffect){
                                                ctx.drawImage(canvasEffectRef.current, 0, y, canvas.width, 3, 0, y, canvas.width, 3);
                                            } else {
                                                ctx.drawImage(videoRef.current, 0, y, canvas.width, 3, 0, y, canvas.width, 3);
                                            }
                                            // ctx.drawImage(videoRef.current, 0, y, canvas.width, 3, 0, y, canvas.width, 3);
                                            requestAnimationFrame(() => drawLineY(y + 3));
                                        } else {
                                            if(visualEffect){
                                                ctx.drawImage(canvasEffectRef.current, 0, y, canvas.width, 3, 0, y, canvas.width, 3);
                                            } else {
                                                ctx.drawImage(videoRef.current, 0, y, canvas.width, 3, 0, y, canvas.width, 3);
                                            }
                                            // ctx.drawImage(videoRef.current, 0, y, canvas.width, 1, 0, y, canvas.width, 1);
                                            requestAnimationFrame(() => drawLineY(y + 1));
                                        }
                                    };
                                    drawLineY(0);
                                } else if(mode === "horizon"){
                                    // 左到右
                                    if(!progressLineRef.current.style) return;
                                    if (isMobile && mobileLens.LensState) {// 手機的自拍(正面)會左右相反，要另外設置
                                        const drawLineX_Mb = (x) => {
                                            if (x >=  canvas.width) {
                                                ctx.restore();
                                                const imgDataUrl = canvas.toDataURL('image/png');
                                                setReCapture(true);
                                                setCapturedImg([imgDataUrl, ...capturedImg]);
                                                saveImage(db, imgDataUrl);
                                                setTimeout(() => { // 拍照按鈕狀態切換延遲
                                                    clearInterval(countdown)
                                                    setImgTaking(true)
                                                }, 500);
                                                clearInterval(countdown);
                                                setChooseImg(0); // 設置顯示index為0 的照片
                                                if (photoSound) {
                                                    soundEffect('/soundEffects/shutterSound.wav');
                                                }
                                                // 隱藏進度線
                                                if(progressLineRef.current){
                                                    progressLineRef.current.style.height = 0;
                                                    progressLineRef.current.style.width = 0;
                                                    progressLineRef.current.style.top = 0;
                                                }
                                                return;
                                            }
                                            if(progressLineRef.current){
                                                progressLineRef.current.style.height = '100%';
                                                progressLineRef.current.style.width = '2px';
                                                progressLineRef.current.style.left = `${((x/canvas.width))*100}%`;
                                            }
                                            if(isMobile){
                                                if(visualEffect){// 有特效就從特效畫布抓取
                                                    ctx.drawImage(canvasEffectRef.current, x, 0, 3, canvas.height, x, 0, 3, canvas.height);
                                                } else {
                                                    ctx.drawImage(videoRef.current, x, 0, 3, canvas.height, x, 0, 3, canvas.height);
                                                }
                                                // ctx.drawImage(videoRef.current, x, 0, 3, canvas.height, x, 0, 3, canvas.height);
                                                requestAnimationFrame(() => drawLineX_Mb(x + 3));// 左至右起始設置
                                            }
                                        };
                                        drawLineX_Mb(0);
                                    } else {
                                        const drawLineX_PC = (x) => {
                                            if (x <= 0 ) {
                                                ctx.restore();
                                                const imgDataUrl = canvas.toDataURL('image/png');
                                                setReCapture(true);
                                                setCapturedImg([imgDataUrl, ...capturedImg]);
                                                saveImage(db, imgDataUrl);
                                                setTimeout(() => { // 拍照按鈕狀態切換延遲
                                                    clearInterval(countdown)
                                                    setImgTaking(true)
                                                }, 500);
                                                clearInterval(countdown);
                                                setChooseImg(0); // 設置顯示index為0 的照片
                                                if (photoSound) {
                                                    soundEffect('/soundEffects/shutterSound.wav');
                                                }
                                                // 隱藏進度線
                                                if(progressLineRef.current){
                                                    progressLineRef.current.style.height = 0;
                                                    progressLineRef.current.style.width = 0;
                                                    progressLineRef.current.style.top = 0;
                                                }
                                                return;
                                            }
                                            if(progressLineRef.current){
                                                progressLineRef.current.style.height = '100%';
                                                progressLineRef.current.style.width = '2px';
                                                progressLineRef.current.style.right = `${((x/canvas.width))*100}%`;
                                            }
                                            if(isMobile){
                                                if(visualEffect){// 有特效就從特效畫布抓取
                                                    ctx.drawImage(canvasEffectRef.current, x, 0, 3, canvas.height, x, 0, 3, canvas.height);
                                                } else {
                                                    ctx.drawImage(videoRef.current, x, 0, 3, canvas.height, x, 0, 3, canvas.height);
                                                }
                                                // ctx.drawImage(videoRef.current, x, 0, 3, canvas.height, x, 0, 3, canvas.height);
                                                requestAnimationFrame(() => drawLineX_PC(x - 3));// 左至右起始設置
                                            } else {
                                                if(visualEffect){// 有特效就從特效畫布抓取
                                                    ctx.drawImage(canvasEffectRef.current, x, 0, 1, canvas.height, x, 0, 1, canvas.height);
                                                } else {
                                                    ctx.drawImage(videoRef.current, x, 0, 1, canvas.height, x, 0, 1, canvas.height);
                                                }
                                                // ctx.drawImage(videoRef.current, x, 0, 1, canvas.height, x, 0, 1, canvas.height);
                                                requestAnimationFrame(() => drawLineX_PC(x - 1));// 左至右起始設置
                                            }
                                        };
                                        drawLineX_PC(canvas.width);
                                    }
                                }
                            }
                        }
                    }, (delaySecond - i) * 1000) 
                }
            }
        } catch (error) {
            console.log(error)
        }
        
    }, [isMobile, imgTaking, cameraState, videoRef, canvasRef, mobileLens, imgQuality, capturedImg, photoSound]);
    
    //重新拍照
    const recaptureImg = useCallback(async () => {
        // console.log('recaptureImg中的狀態',cameraState)
        setReCapture(false);
        setImgTaking(true);
        if(!cameraState){
            // console.log('開啟相機')
            messageShow('.mediaArea', '請先開啟鏡頭', 'Please turn on the camera lens', 2, false)
            return;
        }
        getVideoStream(cameraState, getVideoStream.LensState)
        setChooseImg(null)
    }, [cameraState, getVideoStream]) 

    //=============↓indexDB儲存照片↓===============
    const [db, setDb] = useState(null);
    const openDB = useCallback(() => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('imageDatabase', 1);
        
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                db.createObjectStore('images', { keyPath: 'id', autoIncrement: true });
            };
        
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
        
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }, [])

    const saveImage = useCallback((db, image) => {
        //全部儲存
        // return new Promise((resolve, reject) => {
        //     const transaction = db.transaction(['images'], 'readwrite');
        //     const store = transaction.objectStore('images');
            
        //     image.forEach((img) => {
        //         store.add({ img });
        //     });
        //     transaction.onsuccess = () => {
        //         resolve();
        //     };
        
        //     transaction.onerror = (event) => {
        //         reject(event.target.error);
        //     };
        // });

        //單一儲存
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(['images'], 'readwrite');
            const store = transaction.objectStore('images');
        
            const request = store.add({ image });
        
            request.onsuccess = () => {
                getImages(db).then(images => {
                    // console.log('All images in DB:', images);
                }).catch(error => {
                    console.error('Error fetching images:', error);
                });
                resolve();
            };
        
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }, [])

    const getImages = useCallback((db) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(['images'], 'readonly');
            const store = transaction.objectStore('images');
            const request = store.getAll();
        
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
        
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    },[])

    const deleteImage = useCallback((db, id) => {// 刪除indexDB內的照片
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(['images'], 'readwrite');
            const store = transaction.objectStore('images');
            const request = store.delete(id);
        
            request.onsuccess = () => {
                getImages(db)
                .then(images => {
                    const sortedImages = images.reverse();
                    setCapturedImg(sortedImages.map(img => img.image));
                });
                resolve();
            };
        
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }, [])

    const deleteAllImages = useCallback((db) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(['images'], 'readwrite');
            const store = transaction.objectStore('images');
            const request = store.clear();
        
            request.onsuccess = () => {
                getImages(db)
                .then(images => {
                    const sortedImages = images.reverse();
                    setCapturedImg(sortedImages.map(img => img.image));
                });
                resolve();
            };
        
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }, [])


    // 頁面載入從indexDB抓取圖片
    useEffect(() => {
        openDB()
        .then(database => {
            // console.log('database', database)
            setDb(database);
            getImages(database)
            .then(images => {
                const sortedImages = images.reverse();
                setCapturedImg(sortedImages.map(img => img.image));
                // console.log('images', images)
            });
        });
    }, []);
//=============↑indexDB儲存照片↑===============

    return (
        <CaptureContext.Provider 
            value={{ 
                cameraState, setCameraState,
                sharedValue, setSharedValue,
                mobile, setMobile,
                cameraSizeRatio, setCameraSizeRatio,
                mobileLens, setMobileLens,
                mode, setMode,// 拍攝模式
                capturedImg, setCapturedImg,
                reCapture, setReCapture,
                imgQuality, setImgQuality,
                imgTaking, setImgTaking,
                chooseImg, setChooseImg,
                fnBtnShow, setFnBtnShow,
                videoRef,canvasRef,canvasEffectRef,
                getVideoStream, turnOffVideoStream, 
                messageShow, handleCaptureImg,recaptureImg, 
                progressLineRef,
                getVideoRef, turnOffVideoRef,
                // indexDB儲存圖片
                db, setDb,
                getImages,
                saveImage,deleteImage,
                deleteAllImages
                }}>
        {children}
        </CaptureContext.Provider>
    );
};

export { CaptureContext, CaptureProvider };