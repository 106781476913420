
import VideoCapture from '../components/function/VideoScreenshot/VideoCapture'
import { CaptureProvider } from '../context/CaptureContext';
import { PostureDetectProvider } from '../context/PostureDetectContext';
import { VoiceControlProvider } from '../context/VoiceControlContext';
import { SwitchDetectProvider } from '../context/SwitchDetectContext';
import { FaceDetectProvider } from '../context/FaceDetectContext';
import { CanvasEffectProvider } from '../context/CanvasEffectContext';

const VideoScreenshot = () => {
    return(<>
        <SwitchDetectProvider>
            <CaptureProvider>
                <CanvasEffectProvider>
                    <FaceDetectProvider>
                        <PostureDetectProvider>
                            <VoiceControlProvider>
                                <VideoCapture />
                            </VoiceControlProvider>
                        </PostureDetectProvider> 
                    </FaceDetectProvider>
                </CanvasEffectProvider>
            </CaptureProvider>
        </SwitchDetectProvider>
    </>    
    )
}

export default VideoScreenshot;
