import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zhTranslation from '../src/i18nJson/zh.json';
import enTranslation from '../src/i18nJson/en.json';
const resources = {
    zh: {
        translation: zhTranslation
    },
    en: {
        translation: enTranslation
    },
};

//手動切換
i18n
.use(initReactI18next)
.init({
    resources,
    lng: 'zh', //默認
    fallbackLng: 'zh', // 回退語言
    interpolation: {
      escapeValue: true // 預設為 true，用於避免 XSS 攻擊，會對插入的 HTML 特殊字符進行轉譯。
    }
});

export default i18n;