import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
// import * as Facemesh from "@mediapipe/face_mesh";
import { CaptureContext } from './CaptureContext';
import { FilesetResolver, HandLandmarker, GestureRecognizer, FaceLandmarker, PoseLandmarker } from "@mediapipe/tasks-vision";
import hand_landmarker_task from "../models/hand_landmarker.task";
import gesture_recognizer_task from "../models/gesture_recognizer.task";
import face_landmarker_task from "../models/face_landmarker.task";
// import pose_landmarker_task_full from "../../models/pose_landmarker_full.task";
import { isMobile } from 'react-device-detect';
import { SwitchDetectContext } from './SwitchDetectContext';
// import { SwitchDetectContext } from './SwitchDetectContext';

const PostureDetectContext = createContext();

const PostureDetectProvider = ({children}) => {
    const {
        mobileLens, mode, 
        imgTaking, videoRef,
        canvasRef,handleCaptureImg,canvasEffectRef,
    } = useContext(CaptureContext)
    const { 
        visualEffect 
    } = useContext(SwitchDetectContext)
    // const [postureDetect, setPostureDetect] = useState(false); // 手勢偵測啟動
    const [postureModels, setPostureModels] = useState(false); // 模組載入確認
    const [handleMaker, setHandleMaker] = useState({
        handMarker: null,
        gestureRecognizer: null,
        faceMarker: null
    })
    let animationFrameId;
    let postureLandmarker;
    // let detectTimeoutId;
    // let runningMode = "IMAGE";

    //2. 初始化
    const initializeHandDetection = async () => {
        try {
            const vision = await FilesetResolver.forVisionTasks(
                "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
            );
            const handLandmarker =  await HandLandmarker.createFromOptions(vision, {
            baseOptions: { 
                modelAssetPath: hand_landmarker_task,
                delegate: "GPU"
            },
            numHands: 2,
            runningMode: 'video',
            // runningMode: runningMode
            
            });
            // setHandMarker(handLandmarker)
            
            const gesture = await GestureRecognizer.createFromOptions(vision, {
            baseOptions: { 
                modelAssetPath: gesture_recognizer_task,  
                delegate: "GPU" 
            },
            numHands: 2,
            runningMode: 'video',
            });
            // setGestureRecognizer(gesture)

            // 初始化臉部辨識
            const faceLandmarker = await FaceLandmarker.createFromOptions(vision, {
            baseOptions: { 
                modelAssetPath: face_landmarker_task, 
                delegate: "GPU" 
            },
            numFaces: 1,
            runningMode: 'video',
            });
            // setFaceMarker(faceLandmarker)
            
            setHandleMaker(() => ({
                ...handleMaker,
                handMarker: handLandmarker,
                gestureRecognizer: gesture,
                faceMarker: faceLandmarker
            }))
            // 初始化身體辨識
            // postureLandmarker = await PoseLandmarker.createFromOptions(vision, {
            //   baseOptions: { 
            //     modelAssetPath: pose_landmarker_task_full, 
            //     delegate: "GPU" 
            //   },
            //   runningMode: 'video',
            // });
            setPostureModels(true)
            // detect();// 放裡面一起執行沒問題，如果提出來執行就會執行錯誤
        } catch (error) {
            console.error('Error initializing hand detection:', error);
        }
    };
    const detectTimeoutId = useRef(null)
    // const [detectTimeoutId, setDetectTimeoutId] = useState(null)
    // const [animationFrameId, setAnimationFrameId] = useState(null)
    // let detectTimeoutId;
    let postureControl = {//函式內部使用變數
        touchFace:false,
        handPosture:''
    };
    const [postureCtrl, setPostureCtrl] = useState({
        touchFace:false,
        handPosture:''
    })
    // const [postureControl, setPostureControl] = useState({
    //     touchFace:false,
    //     handPosture:''
    // })
    // 自動拍照的條件
    //3. 偵測
    const detect = (videoRef, handLandmarker, gestureRecognizer, faceLandmarker) => {
        if (videoRef.current && videoRef.current.readyState >= 2) {
            // 手部偵測
            // console.log('檢查handLandmarker',handLandmarker)
            const detections = handLandmarker.detectForVideo(videoRef.current,performance.now());
            // console.log('檢查detections',detections)
            if (detections.landmarks) {
                // console.log('手部偵測')
                // drawLandmarks(detections.landmarks);// 檢視標記用
            }

            // console.log('detections', detections.gestures)
            let nowInMs = Date.now();
            const results = gestureRecognizer.recognizeForVideo(videoRef.current, nowInMs)
            if(results.gestures && results.gestures.length > 0 && results.gestures[0].length > 0){
                // console.log('result', results?.gestures[0][0].categoryName)
                if(results?.gestures[0][0].categoryName === 'Open_Palm'){
                    // console.log('先握拳', postureControl.handPosture)
                    postureControl.handPosture = 'Open_Palm'
                    setPostureCtrl(prev => ({ ...prev, handPosture: 'Open_Palm' }));
                    // console.log('手掌', postureControl)
                } else if(results?.gestures[0][0].categoryName === 'Thumb_Up' ){
                    if(postureControl.handPosture === 'Open_Palm'){
                        postureControl.handPosture = 'Thumb_Up'
                        setPostureCtrl(prev => ({ ...prev, handPosture: 'Thumb_Up' }));
                    }
                } else {
                    postureControl.handPosture = ''
                    setPostureCtrl(prev => ({ ...prev, handPosture: '' }));
                }
                
                // console.log('檢查result', results)
                // 1. None 未知手勢
                // 2. Closed_Fist 握拳
                // 3. Open_Palm 打開手掌
                // 4. Pointing_Up 食指向上
                // 5. Thumb_Down 姆指向下（爛）
                // 6. Thumb_Up 姆指向上（讚）
                // 7. Victory 勝利（食指、中指呈V字型）
                // 8. ILoveYou 愛心（姆指、食指呈愛心）
            } else {
                postureControl.handPosture = ''
                setPostureCtrl(prev => ({ ...prev, handPosture: '' }));
            }


            // 手觸碰臉部偵測
            const faceResults = faceLandmarker.detectForVideo(videoRef.current, nowInMs);
            // console.log('檢查faceResults', faceResults)
            if (faceResults.faceLandmarks) {
                // drawFaceLandmarks(faceResults.faceLandmarks, mobileLens);
                if (detections.landmarks && faceResults.faceLandmarks) {
                    detectHandOnHead(detections.landmarks, faceResults.faceLandmarks);
                }
            }

            // // 臉部偵測 (與其他辨識一起使用時，canvas只能畫出最後執行的辨識模式，但還是可以辨識)
            // const faceResults = faceLandmarker.detectForVideo(videoRef.current, performance.now());
            // // console.log('faceResults', faceResults)
            // if (faceResults.faceLandmarks) {
            //   console.log('臉部偵測')
            //   drawFaceLandmarks(faceResults.faceLandmarks);
            // }

            // 身體偵測 (與其他辨識一起使用時，canvas只能畫出最後執行的辨識模式，但還是可以辨識)
            // const poseResults = postureLandmarker.detectForVideo(videoRef.current, performance.now());
            // console.log('poseResults', poseResults)
            // if (poseResults.landmarks[0]) {
            //   drawPoseLandmarks(poseResults.landmarks[0]);
            // }

        }

         detectTimeoutId.current = setTimeout(() => {
            animationFrameId = requestAnimationFrame(() => detect(videoRef, handLandmarker, gestureRecognizer, faceLandmarker));
        //    setAnimationFrameId(animationFrame)
        }, 100)
        // setDetectTimeoutId(detectTimeout)
    };
    

    const stopDetection = (recapture, canvasRef) => {
        if (animationFrameId) {
            cancelAnimationFrame(animationFrameId);
            // console.log('animationFrameId',animationFrameId)
        }
        if (detectTimeoutId.current) {
            // console.log('清除時間')
            clearTimeout(detectTimeoutId.current);
            detectTimeoutId.current = null;
            // console.log('detectTimeoutId', detectTimeoutId)
            const canvas = canvasRef.current;
            if(!recapture && canvas) {
                const ctx = canvas.getContext('2d');
                ctx.save();
                ctx.setTransform(1, 0, 0, 1, 0, 0);
                ctx.clearRect(0, 0, canvas.width, canvas.height); // 用來倒數拍照時清除偵測方框
                ctx.restore()
                setTimeout(() => {
                    ctx.clearRect(0, 0, canvas.width, canvas.height)// 使用2次消除確保移除殘影
                }, 250)
            }
            // console.log('清除完畢')
        }
    };

    // 手碰到頭執行特別函式
    // const detectHandOnHead = (handLandmarksArray, faceLandmarksArray) => {
    // if(faceLandmarksArray[0]){
    //     const headLandmarks = faceLandmarksArray[0];
    //     handLandmarksArray.forEach(handLandmarks => {
    //         handLandmarks.forEach(handLandmark => {
    //             const handX = handLandmark.x;
    //             const handY = handLandmark.y;
    //             headLandmarks.forEach(headLandmark => {
    //                 const headX = headLandmark.x;
    //                 const headY = headLandmark.y;
    //                 const distance = Math.sqrt((handX - headX) ** 2 + (handY - headY) ** 2);
    //                 if (distance < 0.50 && imgTaking) { // 可調整閾值來控制觸發範圍
    //                     if (postureControl.touchFace) return;
    //                     postureControl.touchFace = true;
    //                     setPostureCtrl(prev => ({ ...prev, touchFace: true }));
    //                         if(postureControl.handPosture === 'Thumb_Up'){
    //                             console.log('mode', mode)
    //                             handleCaptureImg(3, mode)
    //                             postureControl.handPosture =''
    //                             postureControl.touchFace = false;
    //                         }
    //                 } else {
    //                     if (!postureControl.touchFace) return;
    //                     postureControl.touchFace = false;
    //                     setPostureCtrl(prev => ({ ...prev, touchFace: false }));
    //                 }
    //             });
    //         });
    //     });
    // }
    // };
    const detectHandOnHead = (handLandmarksArray, faceLandmarksArray) => {
        if(faceLandmarksArray[0]){
            const headLandmarks = faceLandmarksArray[0];
            handLandmarksArray.forEach(handLandmarks => {
                handLandmarks.forEach(handLandmark => {
                    const handX = handLandmark.x;
                    const handY = handLandmark.y;
                    headLandmarks.forEach(headLandmark => {
                        const headX = headLandmark.x;
                        const headY = headLandmark.y;
                        const distance = Math.sqrt((handX - headX) ** 2 + (handY - headY) ** 2);
                        if (distance < 0.50 && imgTaking) { // 可調整閾值來控制觸發範圍
                            if (postureControl.touchFace) return;
                            postureControl.touchFace = true;
                            setPostureCtrl(prev => ({ ...prev, touchFace: true }));
                            if(postureControl.handPosture === 'Thumb_Up'){
                                // console.log('mode', mode)
                                handleCaptureImg(3, mode, visualEffect)
                                postureControl.handPosture =''
                                postureControl.touchFace = false;
                            }
                        } else {
                            if (!postureControl.touchFace) return;
                            postureControl.touchFace = false;
                            setPostureCtrl(prev => ({ ...prev, touchFace: false }));
                        }
                    });
                });
            });
        }
    };

    //4-1. 繪製手部標記
    const drawLandmarks = (landmarksArray) => {
        if (videoRef.current && canvasRef.current) {
            const fingerJoints = {
                thumb: [1, 2, 3, 4],
                indexFinger: [5, 6, 7, 8],
                middleFinger: [9, 10, 11, 12],
                ringFinger: [13, 14, 15, 16],
                pinky: [17, 18, 19, 20],
            };
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();
            if(isMobile && !mobileLens.LensState){
                ctx.scale(1, 1);
                ctx.translate(0, 0);
            } else {
                ctx.scale(-1, 1); // 左右相反
                ctx.translate(-canvas.width, 0); //調整畫布起始點
            }
            
            const colors = ['red', 'green', 'blue', 'yellow', 'purple']; // Define colors for each joint

            landmarksArray.forEach((landmarks) => {
                landmarks.forEach((landmark, index) => {
                const x = landmark.x * canvas.width;
                const y = landmark.y * canvas.height;

                ctx.fillStyle = colors[index % colors.length]; // Assign color based on index
                ctx.beginPath();
                ctx.arc(x, y, 2, 0, 2 * Math.PI); // Draw a circle for each landmark
                ctx.fill();
                });

                ctx.lineWidth = 1;

                // Draw lines for each finger
                ctx.strokeStyle = 'white'; // Set line color to white
                Object.values(fingerJoints).forEach((finger) => {
                ctx.beginPath();
                for (let i = 0; i < finger.length - 1; i++) {
                    const firstJointIndex = finger[i];
                    const secondJointIndex = finger[i + 1];
                    
                    ctx.moveTo(landmarks[firstJointIndex].x * canvas.width, landmarks[firstJointIndex].y * canvas.height);
                    ctx.lineTo(landmarks[secondJointIndex].x * canvas.width, landmarks[secondJointIndex].y * canvas.height);
                }
                ctx.stroke();
                });
                
                // Connect wrist to the first joint of each finger
                ctx.beginPath();
                const wristIndex = 0; // Assuming wrist index is 0
                for (const finger of Object.values(fingerJoints)) {
                const firstJointIndex = finger[0];
                ctx.moveTo(landmarks[wristIndex].x * canvas.width, landmarks[wristIndex].y * canvas.height);
                ctx.lineTo(landmarks[firstJointIndex].x * canvas.width, landmarks[firstJointIndex].y * canvas.height);
                }
                ctx.stroke();
            });
            ctx.restore();
        }
    };

    //4-2. 繪製臉部標記
    const drawFaceLandmarks = (landmarksArray, mobileLens) => {
        if (videoRef.current && canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();
            if(isMobile && !mobileLens.LensState){
                // console.log('測試 手機 背面', mobileLens.LensState)
                ctx.scale(1, 1);
                ctx.translate(0, 0);
            } else {
                ctx.scale(-1, 1); // 左右相反
                ctx.translate(-canvas.width, 0); //調整畫布起始點
            }
            // const colors = ['red', 'green', 'blue', 'yellow', 'purple'];
            const colors = ['white'];
            ctx.fillStyle = 'cyan';
            landmarksArray.forEach((landmarks) => {
                landmarks.forEach((landmark, index) => {
                const x = landmark.x * canvas.width;
                const y = landmark.y * canvas.height;
                ctx.fillStyle = colors[index % colors.length];
                ctx.beginPath();
                ctx.arc(x, y, 1, 0, 2 * Math.PI);
                ctx.fill();
                });
            });
            ctx.restore();
        }
    };

    //4-3. 繪製火柴人的輪廓(顯示有點問題還須調整)
    const drawPoseLandmarks = (landmarks) => {
        if (videoRef.current && canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();
            if(isMobile && !mobileLens.LensState){
                ctx.scale(1, 1);
                ctx.translate(0, 0);
            } else {
                ctx.scale(-1, 1); // 左右相反
                ctx.translate(-canvas.width, 0); //調整畫布起始點
            }
            ctx.fillStyle = 'magenta';
            ctx.strokeStyle = 'magenta'; // 設置連線的顏色
            ctx.lineWidth = 1; // 設置連線的寬度

            // 繪製頭部
            const headRadius = 5;
            const headLandmark = landmarks[0];
            const headX = headLandmark.x * canvas.width;
            const headY = headLandmark.y * canvas.height;
            ctx.beginPath();
            ctx.arc(headX, headY, headRadius, 0, 2 * Math.PI);
            ctx.fill();

            // 連線身體部分 
            // const bodyParts = [
            //   [11, 12], // 左手臂
            //   [12, 14], // 左手
            //   [11, 13], // 右手臂
            //   [13, 15], // 右手
            //   [0, 1],   // 身體
            //   [1, 2],   // 左大腿
            //   [1, 8],   // 右大腿
            //   [2, 3],   // 左小腿
            //   [8, 9],   // 右小腿
            //   [3, 4],   // 左腳踝
            //   [9, 10]   // 右腳踝
            // ];

            // bodyParts.forEach((part) => {
            //   const [startIdx, endIdx] = part;
            //   const startX = landmarks[startIdx].x * canvas.width;
            //   const startY = landmarks[startIdx].y * canvas.height;
            //   const endX = landmarks[endIdx].x * canvas.width;
            //   const endY = landmarks[endIdx].y * canvas.height;
            //   ctx.beginPath();
            //   ctx.moveTo(startX, startY);
            //   ctx.lineTo(endX, endY);
            //   ctx.stroke();
            // });
            ctx.restore();
        }
    };

    useEffect(() => {
        // if(videoRef.current.readyState >= 2 && cameraState ){
            initializeHandDetection()
        // }
    }, []);


    // useEffect(() => {
       
    //     if(postureModels && videoRef.current && cameraState && handleMaker) {
    //         const {handMarker, gestureRecognizer,faceMarker} = handleMaker;
    //         console.log('有嗎')
    //         detect(videoRef, handMarker, gestureRecognizer,faceMarker)
    //     }
    //     if(postureDetect && videoRef.current.readyState >= 2 && cameraState){
    //     } else {
    //         // if (handLandmarker) {
    //         //     handLandmarker.close();
    //         // }
    //         // if (animationFrameId) {
    //         //     cancelAnimationFrame(animationFrameId);
    //         // }
    //     }
        
    // }, [cameraState, postureModels, videoRef.current, handleMaker])

    return (
        <PostureDetectContext.Provider 
            value={{
                handleMaker, postureModels, detect, stopDetection,
                postureCtrl
            }}>
        {children}
        </PostureDetectContext.Provider>
    );
}
export { PostureDetectContext,  PostureDetectProvider}