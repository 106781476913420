import React, { useState, useReducer, useEffect, memo} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from '../function/LanguageSwitcher';
import ThemeSwitch from '../function/ThemeSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { shareSidebarValue, sidebarSwitchFn } from '../../redux/slice/sidebarSwitchSlice';
import { useTranslation } from 'react-i18next';

function Navbar() {
    const dispatch = useDispatch();
    const sidebarState = useSelector(shareSidebarValue)
    const { t } = useTranslation();
    return (<>
        <NavbarContainer $sidebarstate={sidebarState.state}>
            <Hamburger onClick={() => dispatch(sidebarSwitchFn('switch'))}>
                <FontAwesomeIcon style={{fontSize:'1.5rem'}} icon={faBars}/>
            </Hamburger>
            <div>{t("navbar.title")}</div>
            <ThemeSwitch/>
            <LanguageSwitcher/>
        </NavbarContainer>
    </>)
}

export default memo(Navbar);


const NavbarContainer = styled.nav.attrs({className:'NavbarContainer'})`
    padding-inline: 1rem;
    padding-block: 0.5rem;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 3rem;
    /* max-height: 3rem; */
    /* border: 1px solid black; */
    @media (max-width: 992px) {
        background-color: ${(props) => props.theme.blurBg};
        top: ${(props) => (props.$sidebarstate ? '0' : '-4rem')};
        position: absolute;
        width: calc(100% - 2rem);
        transition:0.5s;
        z-index: 13;
        backdrop-filter:blur(5px);
        -webkit-backdrop-filter:blur(5px);
    }
`;

const Hamburger = styled.nav.attrs({className:'Hamburger'})`
    display: none;
    margin-right: 1rem;
    @media (max-width: 992px) {
        display: block;
    }
`;