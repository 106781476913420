import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
// import { useTranslation } from 'react-i18next';
import * as faceapi from 'face-api.js';
import { FaceMesh } from '@mediapipe/face_mesh';
import { Hands } from '@mediapipe/hands';
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { CaptureContext } from './CaptureContext';
import { SwitchDetectContext } from './SwitchDetectContext';

const FaceDetectContext = createContext();

const FaceDetectProvider = ({ children }) => {  // 一定要使用children名稱
    const {
        cameraState, mobileLens, mode, 
        videoRef,canvasRef,handleCaptureImg,canvasEffectRef,
    } = useContext(CaptureContext)
    const {
        visualEffect
    } = useContext(SwitchDetectContext)

    const [detectFace, setDetectFace] = useState(true);
    const [intervalDetect, setIntervalDetect] = useState(null);

    // 讀取face-api模組
    const loadFaceModels = async () => {
        let MODEL_URL = process.env.PUBLIC_URL + '/models';
        try {
            await Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL)
            ]);
        } catch (error) {
            console.error('Error loading models:', error);
        }
    };

    function drawCustomRect(canvas, detection, color, lineWidth, radius) {
        //radius 參數:5 10 20 圓角大小
        const ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;
        const box = detection.detection._box;

        const x = box.x;
        const y = box.y;
        const width = box.width;
        const height = box.height;

        // 無圓角
        // ctx.rect(box.x, box.y, box.width, box.height);

        // 有圓角完整框
        // ctx.moveTo(x + radius, y);
        // ctx.arcTo(x + width, y, x + width, y + height, radius);
        // ctx.arcTo(x + width, y + height, x, y + height, radius);
        // ctx.arcTo(x, y + height, x, y, radius);
        // ctx.arcTo(x, y, x + width, y, radius);

        // 只有圓角
        // 左上
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x, y, x, y + radius, radius);
        // 右上
        ctx.moveTo(x + width - radius, y);
        ctx.arcTo(x + width, y, x + width, y + radius, radius);
        // 右下
        ctx.moveTo(x + width, y + height - radius);
        ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
        // 左下
        ctx.moveTo(x, y + height - radius);
        ctx.arcTo(x, y + height, x + radius, y + height, radius);

        ctx.stroke();
        }

    // video偵測臉部微笑
    let intervalDetectFace;
    
    const [smileTimes, setSmileTimes] = useState([]);
    const detectFacesInVideo = async (canvasWidth, canvasHeight) => {
        try {
            if (videoRef.current && canvasRef.current) {
                
                const video = videoRef.current;
                const canvas = canvasRef.current;
                // const displaySize = { width: video.width, height: video.height };
                // const displaySize = { width: 1920, height: 1080 };// 暫時先改
                const displaySize = { width:canvasWidth, height: canvasHeight};
                faceapi.matchDimensions(canvas, displaySize);
                let smileTimesArray = [];
                intervalDetectFace = setInterval(async () => {
                    // const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
                    const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
                    const resizedDetections = faceapi.resizeResults(detections, displaySize);
                    const ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.save();
                    if(isMobile && mobileLens.LensState){
                        ctx.scale(1, 1);
                        ctx.translate(0, 0);
                    } else {
                        ctx.scale(-1, 1); // 左右相反
                        ctx.translate(-canvas.width, 0); //調整畫布起始點
                    }
                    // faceapi.draw.drawDetections(canvas, resizedDetections); // 開啟會顯示原始邊框及分數
                    // faceapi.draw.drawFaceExpressions(canvas, resizedDetections); // 顯示表情數值
                    resizedDetections.forEach(detection => {
                        // console.log('detection', detection)
                        if(detection && detection.detection){
                            drawCustomRect(canvas, detection, '#00967b', 2, 25); // 自訂義邊框顏色及樣式
                        }
                        
                        const { expressions } = detection;
                        if (expressions.happy && expressions.happy > 0.5) { // 如果微笑程度大於 50%
                            if(smileTimesArray.length < 5){
                                setSmileTimes(prev => [...prev, 'smile']);// 傳去外部組件使用
                                smileTimesArray.push('smile') // 函式內部記數用
                            } else {
                                // console.log('超過5啦')
                                handleCaptureImg(0, mode, visualEffect)
                                setSmileTimes([])
                                smileTimesArray = [];
    
                            }
                            // console.log('微笑');
                        } else { //如果沒偵測到微笑就重置列表
                            smileTimesArray= []
                            setSmileTimes([])
                        }
                    });
                    ctx.restore();
                }, 500);
                setIntervalDetect(intervalDetectFace)
            }
        } catch (error) {
            console.log(error)
        }
    };

    const stopDetectingFaces = (recapture) => {
        if(intervalDetect) {
            clearInterval(intervalDetect)
            setIntervalDetect(null);
            const canvas = canvasRef.current;
            if(!recapture && canvas) {
                const ctx = canvas.getContext('2d');
                ctx.save();
                ctx.setTransform(1, 0, 0, 1, 0, 0);
                ctx.clearRect(0, 0, canvas.width, canvas.height); // 用來倒數拍照時清除偵測方框
                ctx.restore()
                setTimeout(() => {
                    ctx.clearRect(0, 0, canvas.width, canvas.height)// 使用2次消除確保移除殘影
                }, 250)
            }
            
        }
    };




    const loadMediapipe = async () => {
        // const faceMesh = new FaceMesh();
        // const hands = new Hands();
        const faceMesh = new FaceMesh({ locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}` });
        const hands = new Hands({ locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}` });

        faceMesh.setOptions({
            maxNumFaces: 1,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });

        hands.setOptions({
            maxNumHands: 1,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });

        faceMesh.onResults(onResults);
        hands.onResults(onResults);

        if (videoRef.current && cameraState) {
            setInterval(async () => {
                if (videoRef.current) {
                    try {
                        await faceMesh.send({ image: videoRef.current });
                        await hands.send({ image: videoRef.current });
                    } catch (error) {
                        console.error("Error during frame processing", error);
                    }
                }
            }, 1000 / 30); // 30 frames per second
        }
    };

    const onResults = (results) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, 640, 480);
        ctx.drawImage(results.image, 0, 0, 640, 480);

        if (results.multiFaceLandmarks) {
            for (const landmarks of results.multiFaceLandmarks) {
                drawConnectors(ctx, landmarks, FaceMesh.FACEMESH_TESSELATION, { color: '#C0C0C070', lineWidth: 1 });
                drawLandmarks(ctx, landmarks, { color: '#FF3030', lineWidth: 2 });
            }
        }

        if (results.multiHandLandmarks) {
            for (const landmarks of results.multiHandLandmarks) {
                drawConnectors(ctx, landmarks, Hands.HAND_CONNECTIONS, { color: '#00FF00', lineWidth: 5 });
                drawLandmarks(ctx, landmarks, { color: '#FF0000', lineWidth: 2 });
            }
        }
    };


    return (
        <FaceDetectContext.Provider 
            value={{ 
                detectFace, setDetectFace,
                smileTimes, setSmileTimes,
                loadFaceModels, drawCustomRect, 
                detectFacesInVideo, stopDetectingFaces, 
                loadMediapipe
            }}>
        {children}
        </FaceDetectContext.Provider>
    );
};

export { FaceDetectContext, FaceDetectProvider };