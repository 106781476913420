import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components'

export const outLineNone = css`
    &:focus {
        outline: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
`
export const fontSize = css`
    font-size: clamp(0.5rem, 4vw, 0.9rem);
`

export const StyledIcon = styled(FontAwesomeIcon)`
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

export const IconBtn = styled.div`
font-size: 2rem;
color: #ffffffd4;
z-index: 2;
cursor: pointer;
filter: drop-shadow(0px 0px 2px black);
&:hover {
    ${!isMobile && css`
        color: ${props => `${props.theme.textColorHover}`};
    `}
}
&:active {
    ${isMobile && css`
        color: ${props => `${props.theme.textColorHover}`};
    `}
}
`;
