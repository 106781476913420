import { memo, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconBtn, outLineNone } from '../../../styledComponents/btnStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPen } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { CaptureContext } from '../../../context/CaptureContext';
import JSZip from 'jszip';
// import { messageShow } from '../messageShow';

const EditFunctionalBtn = () => {
    const {
        capturedImg, 
        chooseImg,
        fnBtnShow, setFnBtnShow,
        messageShow,
        // indexDB儲存圖片
        db, getImages,
        deleteImage,deleteAllImages
    } = useContext(CaptureContext);
    const { t, i18n } = useTranslation();

    const deleteImg = (qty, chooseImg) => {
        try {
            //capturedImg i18n chooseImg qty
            let newcapturedImg = [...capturedImg];
            if(newcapturedImg.length === 0) {
                return messageShow('.mediaArea', '無儲存的照片', 'No photos saved', 2, false);
            }
            if(qty===1){
                // console.log('chooseImg', chooseImg)
                // newcapturedImg.shift();// 原刪除最近一次的拍照
    
                // 刪除indexDB中的照片
                getImages(db) 
                .then(img => {
                    let reverseImg = img.reverse();
                    if(!reverseImg[chooseImg]) return;
                    deleteImage(db, reverseImg[chooseImg].id);
                    // console.log('img', img[chooseImg].id)
                })
                // 刪除chooseImg中的照片
                newcapturedImg.splice( chooseImg,1);
            } else if (qty === 'all'){
                const content =  i18n.language === "zh" ? "確定要刪除所有照片嗎?":"Are you sure you want to delete all photos?"
                const confirmed = window.confirm(content);
                if (!confirmed) {
                    return;
                }
                deleteAllImages(db)
                newcapturedImg = []
            }
            
        } catch (error) {
            console.log(error)
        }
        return;
    }

    const handleDownloadImg = (capturedImg) => {
        //capturedImg i18n isMobile
        if (capturedImg.length === 0) return messageShow('.mediaArea', '無照片可下載', 'No photo download', 2, false);
        if (capturedImg) {
            if(isMobile) {
                capturedImg.forEach((item, index) => {
                    fetch(item)
                    .then(response => response.blob())
                    .then(blob => {
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = `image-${new Date().getTime()}.png`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    });
                });
            } else {
                const zip = new JSZip();
                // 使用 Promise.all 確保所有圖片都被下載
                const fetchPromises = capturedImg.map((item, index) => {
                    return fetch(item)
                        .then(response => response.blob())
                        .then(blob => {
                            zip.file(`image-${index + 1}.png`, blob, { binary: true });
                        });
                });

                Promise.all(fetchPromises)
                    .then(() => {
                        zip.generateAsync({ type: 'blob' })
                        .then(content => {
                            const url = window.URL.createObjectURL(content);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            a.download = `captured-images-${new Date().getTime()}.zip`;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        });
                    })
                    .catch(error => {
                        console.error('Failed to fetch one or more images:', error);
                    }
                );
            }
        }
    };

    return (
        <div style={{position:'fixed', right:'1rem',textAlign:'center', bottom:'50%',transform:'translateY(50%)'}}>
        <SideBtnArea $fnbtnshow={fnBtnShow.toString()}>
            <SideBtnFunction onClick={() => deleteImg(1,chooseImg)}>{t("videoCapture.deleteCurrent")}</SideBtnFunction>
            <SideBtnFunction onClick={() => deleteImg('all', '')}>{t("videoCapture.deleteAll")}</SideBtnFunction>
            <SideBtnFunction onClick={() => handleDownloadImg(capturedImg)}>{t("videoCapture.download")}</SideBtnFunction>
        </SideBtnArea> 
        <IconBtn>
            <FontAwesomeIcon icon={faPen} onClick={() => setFnBtnShow(prev => !prev)}/>
                {/* <FontAwesomeIcon icon={faGear} onClick={() => setFnBtnShow(prev => !prev)}  /> */}
        </IconBtn>   
    </div>
    )
}

export default memo(EditFunctionalBtn);

const SideBtnArea = styled.div.attrs({className: 'SideBtnArea'})`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5rem;
    padding: 0.1rem;
    display: ${props => props.$fnbtnshow==='true' ? 'block' : 'none'};
    overflow: hidden;
`
const SideBtnFunction = styled.button.attrs({className: 'SideBtnFunction'})`
    color: ${props => props.theme.btnCaptureText ? `${props.theme.btnCaptureText}`: `${props.theme.btnCaptureText}`} !important;
    /* position: relative; */
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.blurBg};
    align-items: center;
    font-weight: bold;
    font-size:large;
    width: 6rem;
    height: 5rem;
    margin-block: 1rem;
    padding-inline:1rem;
    border-radius: 5px;
    border: none;
    white-space: break-spaces;
    box-shadow: ${props => `0 0 0 1px ${props.theme.textColor}` };
    /* background-color: red !important; */
    backdrop-filter:blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: pointer;
    ${outLineNone};
    &:hover {
        ${!isMobile && css`
            & {
                box-shadow: ${props => `0 0 0 3px ${props.theme.textColorHover}` };
            }
        `}
    }

    &:active {
        ${isMobile && css`
            & {
                box-shadow: ${props => `0 0 0 3px ${props.theme.textColorHover}` };
            }
        `}
    }
`