import { configureStore } from '@reduxjs/toolkit';
import ThemeSwitchSliceReducer from '../slice/shareThemeSlice';
import SidebarSwitchSliceReducer  from '../slice/sidebarSwitchSlice';

const store = configureStore({
    reducer: {
        themeSwitch: ThemeSwitchSliceReducer,
        sidebarSwitch: SidebarSwitchSliceReducer
    }
})

export default store;