import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { themeSwitchFn } from '../../redux/slice/shareThemeSlice';
import { setCookie, getCookie } from './SetCookie';
const ThemeSwitch = () => {
    const dispatch = useDispatch();
    const [theme, setTheme] = useState('default');
    
    useEffect(() => {
        const cookieData = getCookie('theme');
        dispatch(themeSwitchFn(cookieData));
        setTheme(cookieData)
    }, []);
    
    return (
        <SwitchDiv>
            {
                theme === 'dark' && 
                <BtnCursor>
                    <FontAwesomeIcon icon={faSun} style={{fontSize:'x-large'}} 
                        onClick={() => {
                            dispatch(themeSwitchFn('default'))
                            setTheme('default')
                            setCookie('theme', 'default', 180)
                        }}
                    />
                </BtnCursor>
            }
            {
                (theme === 'default' || theme === '') &&
                <BtnCursor>
                    <FontAwesomeIcon icon={faMoon} style={{fontSize:'x-large'}} 
                        onClick={() => {
                            dispatch(themeSwitchFn('dark'))
                            setTheme('dark')
                            setCookie('theme', 'dark', 180)
                        }}
                    />
                </BtnCursor>
                
            }
        </SwitchDiv>
        
    )
}

export default ThemeSwitch;

const SwitchDiv = styled.div`
    margin-left: auto;
`;
const BtnCursor = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
`;