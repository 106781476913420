import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import '../src/css/App.css';
import '../src/css/hover.css';
// import { useTranslation } from 'react-i18next';
// import LanguageSwitcher from './components/i18n/LanguageSwitcher';
// import VideoStream from './components/function/VideoStream';
// import VideoCapture from './components/function/VideoCapture';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { AppContent } from './styledComponents/styledTheme';
import FrontLayout from './pages/FrontLayout';
import VideoScreenshot from './pages/VideoScreenshot';
import VideoRecord from './pages/VideoRecord';
import { shareThemeValue } from './redux/slice/shareThemeSlice';
import NotFound from './pages/NotFound';
import Loading from './components/function/Loading';

const App = () => {
    const themeState = useSelector(shareThemeValue)
    return (
        <ThemeProvider theme={themeState.theme}>
            <Loading />
            <AppContent className="App">
                <Routes>
                    <Route path="/" element={<FrontLayout/>}>
                        <Route path="" index element={<VideoScreenshot/>}></Route>
                        {/* <Route path="record" element={<VideoRecord/>}></Route> */}
                        {/* <Route path="*" element={<NotFound />} /> */}
                    </Route>
                </Routes>
            </AppContent>
        </ThemeProvider>
    );
}

export default App;

