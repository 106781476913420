import React, { memo, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes} from 'styled-components';
import { isMobile } from 'react-device-detect';
import CarouselSlick from './CarouselSlick';
import { preventTouchMove } from '../mobileBrowser/touch';
import { CaptureContext } from '../../../context/CaptureContext';
import { PostureDetectContext } from '../../../context/PostureDetectContext';
import EditFunctionalBtn from './EditFunctionalBtn';
import SettingFunctionalBtn from './SettingFunctionalBtn';
import CaptureBtn from './CaptureBtn';
import LensesChange from './LensesChange';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faFaceSmile, faHand, faRotateLeft, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { VoiceControlContext } from '../../../context/VoiceControlContext';
import { SwitchDetectContext } from '../../../context/SwitchDetectContext';
import { FaceDetectContext } from '../../../context/FaceDetectContext';
import RangeControlled from '../RangeControlled';
import { CanvasEffectContext } from '../../../context/CanvasEffectContext';
import { ElementToggle } from '../ElementToggle';


const VideoCapture = () => {
    const { t, i18n } = useTranslation();
    const {
        smileDetect, postureDetect, visualEffect
    } = useContext(SwitchDetectContext)

    const {
        cameraState, setMobile, 
        cameraSizeRatio, setCameraSizeRatio,
        mobileLens, capturedImg,
        reCapture, setReCapture,
        imgTaking, chooseImg, 
        setChooseImg,
        videoRef,canvasRef,canvasEffectRef,
        getVideoStream, turnOffVideoStream,
        messageShow, progressLineRef
    } = useContext(CaptureContext);

    const {
        smileTimes
    } = useContext(FaceDetectContext)
    const {
        postureCtrl
    } = useContext( PostureDetectContext);

    const {
        effectIntensity, setEffectIntensity, rangeRef,
        redHueRef,greenHueRef,blueHueRef,brightnessRef,
        hueRedValue, setHueRedValue,
        hueGreenValue, setHueGreenValue,
        hueBlueValue, setHueBlueValue,
        brightness, setBrightness
    } = useContext(CanvasEffectContext);

    const hueControlRef = useRef(null);
    const [hueInterfaceShow, setHueInterfaceShow] = useState(false); // 色調調整介面開關
    useEffect(() => {
        if(hueControlRef.current && hueInterfaceShow) {
            return ElementToggle(hueControlRef, ()=> {
                setHueInterfaceShow(false)
            })
        }
    }, [hueInterfaceShow])

    useEffect(() => {
        messageShow('.mediaArea', '開啟相機進行拍照', 'Turn on the camera to take pictures', 2, false)
        if(!isMobile) { // 1.檢測:不是手機裝置
            setMobile(false)
        } else {// 檢測:手機裝置
            preventTouchMove();// 禁止手機滑動預設行為
            setMobile(true)
            setCameraSizeRatio(prev => ({...prev, 
                ratio:0.5625,
                width: 1080,
                height: 1920
            }))
        }
    }, [])

    useEffect(() => { // 切換頁面時清除視訊鏡頭串流
        const cleanup = () => {
            if (cameraState) {
                cameraState.getTracks().forEach(track => track.stop());
                // setCameraState(null);
            }
        };
        return () => {
            cleanup();
        };
    }, [cameraState]);

    const currentVideoRef = useRef(videoRef.current);
    const [streamSizeRatio, setStreamSizeRatio] = useState(null)
    useEffect(() => {
        currentVideoRef.current = videoRef.current;
        const handleVideoSize = () => {
            const ratio = currentVideoRef.current.videoWidth/currentVideoRef.current.videoHeight;
            setStreamSizeRatio(ratio)
        };
        if (currentVideoRef.current) {
            currentVideoRef.current.addEventListener('loadedmetadata', handleVideoSize);
        }
        return () => {
            if (currentVideoRef.current) {
                currentVideoRef.current.removeEventListener('loadedmetadata', handleVideoSize);
            }
        };
    }, [videoRef]); 

    //測試語音功能是否正常
    // const [test, setTest] = useState([]) 
    // useEffect(() => {
    //     console.log('transcript', transcript)
    //     setTest(prevTest => [...prevTest, transcript]);
        
    // }, [transcript])

    // useEffect(() => {
    //     console.log('testt', test)
        
        
    // }, [test])

//   useEffect(() => {
//     if (videoRef.current && canvasRef.current && cameraState) {
//       loadMediapipe();
//     }
//   }, [cameraState, videoRef.current, canvasRef.current]);

    // 當手機改為橫向時，要重啟抓取video和canvas尺寸，所以用開關鏡頭表示
    useEffect(() => {
        const handleResize = () => {
            if(cameraState){
                Promise.resolve()
                .then(() => {
                    turnOffVideoStream()
                    return Promise.resolve();
                })
                .then(() => {
                    getVideoStream(null, mobileLens.LensState);
                    return Promise.resolve();
                })
                .catch((error) => {
                    console.error("Error turning off video stream:", error);
                });
                
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        
        };
    }, [cameraState]);


    // 監聽圖片選擇區並給予滾輪滾動
    const imgSlideRef = useRef(null);
    const handleMouseEnter = () => {
        if (imgSlideRef.current) {
            imgSlideRef.current.addEventListener('wheel', handleWheel);
        }
    };
    
    const handleMouseLeave = () => {
        if (imgSlideRef.current) {
            imgSlideRef.current.removeEventListener('wheel', handleWheel);
        }
    };
    
    const handleWheel = (event) => {
        if (imgSlideRef.current) {
            event.preventDefault();
            imgSlideRef.current.scrollLeft += event.deltaY * 3;
        }
    };


    return (
        <VideoCaptureAreaDiv>
            <VideoCaptureAreaInsideDiv>
                {/* 媒體顯示區域 */}
                {/* <div style={{ position:'relative',display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height:'50%'}}> */}
                <VideoAreaDiv>
                    {  isMobile && !reCapture && cameraState && //相機前後鏡頭切換按鈕
                        <LensesChange/>
                    }
                    <VideoDiv className='mediaArea'>
                        { videoRef.current && canvasRef.current && cameraState && !reCapture &&
                            <CanvasProgessDiv style={{height:videoRef?.current?.clientHeight, width:videoRef?.current?.clientWidth}}>
                                <CanvasProgessLine ref={progressLineRef}></CanvasProgessLine>
                            </CanvasProgessDiv>
                        }
                        <VideoElement style={{display:reCapture? 'none':''}} size={cameraSizeRatio} ref={videoRef} $lence={mobileLens} autoPlay playsInline >
                        </VideoElement>
                        {
                            !reCapture && cameraState &&
                            <div style={{position:'absolute', top:'0.5rem', right:'0.5rem', backgroundColor:'rgb(157 157 157 / 74%)', borderRadius: '0.5rem',padding:'0.5rem', backdropFilter:'blur(10px)', zIndex:10}}>
                                <table>
                                    <tbody>
                                        {
                                            smileDetect && 
                                            <tr>
                                                <td style={{ padding: '0.1rem' }}>{t("videoCapture.detectText_smile")}</td>
                                                <td>
                                                    <FontAwesomeIcon style={{ marginRight: '0.5rem' , color:smileTimes.length? '#fff500': '', opacity:smileTimes.length? smileTimes.length/5 : '1', transition: 'all 0.3s'}} icon={faFaceSmile} />
                                                </td>
                                            </tr>
                                        }
                                        {
                                            postureDetect && (<>
                                                <tr>
                                                    <td>{t("videoCapture.detectText_gesture")}</td>
                                                        <td>
                                                            <FontAwesomeIcon style={{ color:(postureCtrl.touchFace && postureCtrl.handPosture==='Open_Palm')||(postureCtrl.touchFace && postureCtrl.handPosture==='Thumb_Up')? '#fff500': '' , transition: 'all 0.3s'}} icon={faHand} />
                                                            <span style={{marginInline:'0.5rem'}}>→</span>
                                                            <FontAwesomeIcon style={{ color:(postureCtrl.touchFace && postureCtrl.handPosture==='Thumb_Up')? '#fff500': '' , transition: 'all 0.3s'}} icon={faThumbsUp} />
                                                        </td>
                                                    </tr>
                                        </>)
                                        }
                                        {/* postureCtrl */}
                                        {// 測試語音功能: 文字顯示在畫面上
                                                // <tr>
                                                //     <td style={{ padding: '0.1rem' }}> 
                                                //         {test.map((item, index) => (
                                                //             <div key={index}>{item}</div>
                                                //         ))}
                                                //     </td>
                                                // </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        { !reCapture && cameraState &&
                            <>
                                <CanvasElement ref={canvasEffectRef} style={{aspectRatio:streamSizeRatio, transform:'translate(-50%, -50%) '}}/>{/* 特殊效果要放在不同canvas才可以正確將特殊效果畫面繪製到canvasRef*/}
                                <CanvasElement ref={canvasRef} style={{aspectRatio:streamSizeRatio}}/> {/*此canvas要在下方，否則掃描模式會不正常 */}
                            </>
                        }
                        <SlideImgDiv size={cameraSizeRatio} >
                            <SlideImgInsideDiv>
                                {capturedImg.length !== 0 && reCapture && 
                                    // <CarouselSlick captureImg={capturedImg} SlideToImg={chooseImg} setSlideToImg={setChooseImg}/>
                                    <CarouselSlick captureImg={capturedImg}/>
                                }
                            </SlideImgInsideDiv>
                        </SlideImgDiv> 
                        {/* 特效Range組件 */}
                        { (visualEffect==='fishEye' || visualEffect==='catEye' ) && !reCapture && cameraState &&
                            <RangeControlDiv style={{visibility: imgTaking?'':'hidden'}}>
                                <RangeControlled  value={effectIntensity} setFn={setEffectIntensity} elementRef={rangeRef}/>
                            </RangeControlDiv>
                        }
                        { visualEffect==='imgHue' && !reCapture && cameraState &&
                        <>
                            <HueControlDiv ref={hueControlRef} className={hueInterfaceShow ? 'active' : ''} style={{visibility: imgTaking?'':'hidden', display: 'flex', flexDirection:'column', backgroundColor:'rgb(0 0 0 / 68%)', borderRadius:'0.5rem', padding:'0.2rem'}}>
                                
                                {/* { hueInterfaceShow && <>  */}
                                    {/* <FadeInUpDiv > */}
                                        <RangeControlled color={'#ff191942'} min={-255} max={255} value={hueRedValue} setFn={setHueRedValue} elementRef={redHueRef} animationFrame={true} />
                                        <RangeControlled color={'#006500'} min={-255} max={255} value={hueGreenValue} setFn={setHueGreenValue} elementRef={greenHueRef} animationFrame={true} />
                                        <RangeControlled color={'#315eff'} min={-255} max={255} value={hueBlueValue} setFn={setHueBlueValue} elementRef={blueHueRef} animationFrame={true} />
                                        <RangeControlled color={'#989898'} min={-255} max={255} value={brightness} setFn={setBrightness} elementRef={brightnessRef} animationFrame={true} />
                                        <FontAwesomeIcon icon={faRotateLeft} style={{cursor:'pointer', marginInline:'auto', color:'#00967b', marginTop:'0.5rem'}} onClick={() => {
                                            setHueRedValue(0)
                                            setHueGreenValue(0)
                                            setHueBlueValue(0)
                                            setBrightness(0)
                                        }}/>
                                    {/* </FadeInUpDiv> */}
                                {/* </>} */}
                            </HueControlDiv>
                            { !hueInterfaceShow &&
                                <StyledFontAwesomeIcon icon={faAngleUp} onClick={() => setTimeout(() => {setHueInterfaceShow(true)},0)} />
                            }
                        </>
                        }


                    </VideoDiv>
                    {/* <canvas ref={canvasRef} style={{ display: 'none' }} /> */}
                    
                    
                    {/* 齒輪按鈕 */}
                    {/* { reCapture && <SideFunctionalBtn /> } */}
                {/* </div> */}
                </VideoAreaDiv>
                {/* 照片列表區域 */}
                {/* { !mobile && 
                    <div style={{ minHeight: '100px', maxHeight: '100px', maxWidth:'640px', width:'100%', height:'20%', overflowX: 'scroll', display: 'flex', padding:'0.5rem 0'}}>
                        {capturedImg.map((item, index) => {
                            return <ChooseThumbnailImg key={index} src={item} alt={`Captured${index}`} $active={index === chooseImg? "true": ""} 
                            onClick={() => {
                                setChooseImg(index);
                                if(reCapture) return ;
                                setReCapture(true);
                            }}/>
                        })}
                    </div>
                } */}
                <PcSlideAreaDiv ref={imgSlideRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{pointerEvents:imgTaking? 'auto':'none'}}>
                    <PcSlideAreaInsideDiv>
                        {capturedImg.map((item, index) => {
                            return <ChooseThumbnailImg key={index} src={item} alt={`Captured${index}`} $active={index === chooseImg? "true": ""} 
                            onClick={() => {
                                setChooseImg(index);
                                if(reCapture) return ;
                                setReCapture(true);
                            }}/>
                        })}
                    </PcSlideAreaInsideDiv>
                </PcSlideAreaDiv>
                
                
                {/* 拍照按鈕區域 */}
                <CaptureBtnAreaDiv>
                    <CaptureBtn />
                </CaptureBtnAreaDiv>
                {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', maxWidth:'680px', height:'30%'}}></div> */}
            </VideoCaptureAreaInsideDiv>
            { reCapture && <EditFunctionalBtn /> }
            { !reCapture && <SettingFunctionalBtn />}
        </VideoCaptureAreaDiv>
    );
};

export default memo(VideoCapture);




const spinLinear = keyframes`
    from {
        border-image: linear-gradient(to bottom right, #ff006a, #2a4aff) 1;
    }
    25% {
        border-image: linear-gradient(to bottom left, #ff006a, #2a4aff) 1;
    }
    50% {
        border-image: linear-gradient(to top left, #ff006a, #2a4aff) 1;
    }
    75% {
        border-image: linear-gradient(to top right, #ff006a, #2a4aff) 1;
    }
    to {
        border-image: linear-gradient(to bottom right, #ff006a, #2a4aff) 1;
    }
`;

//外
const VideoCaptureAreaDiv = styled.div.attrs({className: 'VideoCaptureAreaDiv'})`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    
`

//內
const VideoCaptureAreaInsideDiv = styled.div.attrs({className: 'VideoCaptureAreaInsideDiv'})`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* max-width: 560px; */
    max-width: 640px;
    width: 100%;
    @media (max-width: 992px) {
        max-width: 100%;
        height: 100%;
    }
    
`

//上
const VideoAreaDiv = styled.div.attrs({className:'VideoAreaDiv'})`
    width: 100%;
    max-height: 360px;
    height: 45vh;
    /* background-color: aquamarine; */
    /* padding-block: 0.5rem; */
    @media (max-width: 992px) {
        height: 100%;
        max-height: 100%;
        padding-block: 0;
    }
`
const VideoDiv = styled.div.attrs({className: 'VideoDiv'})`
    /* display: none; */
    overflow: hidden;
    position: relative;//canvas使用
    height: 100%;
    width: 100%;
    @media (min-width: 992px) {
        border:${props => isMobile? `none`: `1px solid ${props.theme.border}` };
    }
`
const CanvasProgessDiv = styled.div.attrs({className: 'CanvasProgessDiv'})`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    /* background-color: red; */
`

const CanvasProgessLine = styled.div.attrs({className: 'CanvasProgessLine '})`
    /* width: 100%; */
    /* height: 100%;
    width: 2px; */
    background-color: #00dfc6;
    position: absolute;
    /* top: 0; */
    /* left: 50%; */
    z-index: 1;
`

const VideoElement = styled.video.attrs({className: 'VideoElement'})` 
    /* display:none; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${props => (isMobile && props.$lence.LensState)? `translate(-50%, -50%)`:`translate(-50%, -50%) scaleX(-1)`};
    /* transform: translate(-50%, -50%) scaleX(-1); */
    /* width: 100%; */
    /* height: 100%; */
    max-width: 640px;
    /* max-height: 100%; */
    @media (max-width: 768px) {
        max-width: 100%;
    }
`

const CanvasElement = styled.canvas.attrs({className: 'CanvasElement'})`//canvas基本尺寸預設為300*150
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 640px;
    /* aspect-ratio: 1.778; */
    @media (max-width: 768px) {
        max-width: 100%;
    }
`


const SlideImgDiv = styled.div.attrs({className: 'SlideImgDiv'})`
    /* display:none; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 100%; */
    /* height: 100%; */
    width: 100%;
    max-width: 640px;
    @media (max-width: 768px) {
        height: 100%;
        max-width: 100%;
        display: flex;
    }
    /* max-height: 100%; */
`;

const RangeControlDiv = styled.div.attrs({className: 'RangeControlDiv'})`
    position: absolute; 
    z-index:11;
    left: 50%;
    bottom: 1rem;
    transform: translate(-50%, 0);
    @media (max-width: 768px) {
        bottom: 35%;
        transform: translate(-50%, -50%);
    }
`

const HueControlDiv = styled.div.attrs({className: 'HueControlDiv'})`
    visibility: hidden;
    opacity: 0;
    position: absolute; 
    z-index:11;
    right: 1rem;
    bottom: 1rem;
    transform: translate(0%, 0%);
    /* transform: translateY(20px); */
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    &.active {
        visibility: unset;
        opacity: 1;
        transform: translate(0%, -10%);
    }
    @media (max-width: 768px) {
        transform: translate(-50%, -40%);
        right: auto;
        bottom: auto;
        left: 50%;
        bottom: 25%;
        &.active {
            visibility: unset;
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: larger;
    z-index: 11;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: white;
    filter: drop-shadow(0 1px 2px black);
    @media (max-width: 768px) {
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 35%;
    }
    &:hover {
        color: #00967b;
    }
`;

const SlideImgInsideDiv = styled.div.attrs({className: 'SlideImgInsideDiv'})`
    display:flex;
    width:100%;
    @media (max-width: 768px) {
        margin: auto;
    }
    /* max-height: 100%; */
`;



//中
const PcSlideAreaDiv = styled.div.attrs({className: 'PcSlideAreaDiv'})`
    width: 100%;
    height: 100%;
    display: flex;
    /* min-height: 7vh; */
    padding-block: 0.5rem;
    height: 15vh;
    max-height: 5rem;
    overflow: scroll hidden;
    scroll-behavior: smooth;
    /* background-color: azure; */
    @media (max-width: 992px) {
        display: none;
    }
`
const PcSlideAreaInsideDiv = styled.div.attrs({className: 'PcSlideAreaInsideDiv'})`
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 640px;
    margin: auto;
    /* overflow: scroll hidden; */
    /* border: 1px solid gray; */
`

const ChooseThumbnailImg = styled.img.attrs({className: 'ChooseThumbnailImg'})`
    height: 100%;
    margin-block: auto;
    margin-right: 0.5rem;
    ${props =>props.$active && css`
        border: 2px solid #00000000;
        border-image: linear-gradient(to bottom right, #ff006a, #2a4aff) 1;
        animation: ${spinLinear} 2s linear infinite;
    `}
`;


//下
const CaptureBtnAreaDiv = styled.div.attrs({className: 'CaptureBtnAreaDiv'})`
    width: 100%;
    /* overflow: hidden; */
    display: flex;
    padding-block: 0.5rem;
    z-index: 1;
    @media (max-width: 992px) {
        height: auto;
        position: fixed;
        bottom: 3rem;
    }
`
const rotateAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const ProgressIconForeground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 15px solid #514b82;
    clip-path: ${(props) =>
        `polygon(50% 50%, 0 0, ${props.progress}% 0, ${props.progress}% 100%, 0 100%, 0 0)`};
    animation: ${rotateAnimation} 2s linear infinite;
`;