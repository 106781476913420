import { createSlice } from '@reduxjs/toolkit';

const defaultMode = {
    textColor:'#3b3b3b',
    textColorHover:'#3b3b3b9c',
    backgroundColor:'white',
    linkColor:'#71a3dc',
    btnBgColor:'',
    boxShadow:'#00967b',
    boxShadowHover:'#00967bc9',
    btnCaptureText:'#00967b',
    blurBg: '#d6d6d6c7',
    blurBord: '',
    border: '#3b3b3b',
    modalColor:'#f0f0f0d9',
}
const darkMode = {
    textColor:'#dadada',
    textColorHover:'#dadada9c',
    backgroundColor:'black',
    linkColor:'#71a3dc',
    btnBgColor:'',
    boxShadow:'#00967b',
    boxShadowHover:'#00967bc9',
    btnCaptureText:'#00967b',
    blurBg: '#2e2e2ec7',
    blurBord: '',
    border: '#dadada',
    modalColor:'#000000d9',
}
const initialState = {
    theme:defaultMode
}

const ThemeSwitchSlice = createSlice({
    name: 'themeSwitch',
    initialState,
    reducers: {
        themeSwitchFn: (state, action) => {
            switch (action.payload) {
                case 'default':
                    return {theme:defaultMode};
                case 'dark':
                    return {theme:darkMode};
                default:
                    return state;
            }
        }
    }
})


export const {themeSwitchFn} = ThemeSwitchSlice.actions;
export const shareThemeValue = state => state.themeSwitch;
export default ThemeSwitchSlice.reducer;