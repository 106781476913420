import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { shareSidebarValue } from '../../redux/slice/sidebarSwitchSlice';
import { useSelector } from 'react-redux';
import { memo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { StyledIcon } from '../../styledComponents/btnStyle';

const Sidebar = ({ dataSet, data}) => {
    const { t } = useTranslation();
    const sidebarState = useSelector(shareSidebarValue)
    
    return (
        <SidebarContainer $sidebarstate={sidebarState.state}>
            <SidebarUl>
                <Link to='/' style={{textDecoration:'none'}}>
                    <SidebarTextDiv className='textHover'>{t('sidebar.videoScreenShot')}</SidebarTextDiv>
                </Link>
                {/* <Link to='/record' style={{textDecoration:'none'}}>
                    <SidebarTextDiv className='textHover'>{t('sidebar.videoRecord')}</SidebarTextDiv>
                </Link> */}
            </SidebarUl>
            <StyledIcon icon={faBullhorn} style={{color:'#00967b', marginTop:'auto', position:'unset', marginBottom:'5rem', fontSize:'2rem'}} onClick={() => setTimeout(() => {dataSet(!data)},0)} />
        </SidebarContainer>
    )
}

export default memo(Sidebar);

const SidebarContainer = styled.div.withConfig({
        shouldForwardProp: (prop) => !['sidebarState'].includes(prop)
    })`
    height: calc(100vh - 4rem);
    width: 10rem;
    transition:0.5s;
    overflow-x:hidden;
    display: flex;
    flex-direction:column;
    align-items:center;
    @media (max-width: 992px) {
        top:4rem;
        /* transform:${(props) => (props.sidebarState ? '' : 'translateX(-100%)')}; */
        width:${(props) => (props.$sidebarstate ? '10rem' : '0')};
        position: absolute;
        background-color: ${(props) => props.theme.blurBg};
        backdrop-filter:blur(5px);
        -webkit-backdrop-filter:blur(5px);
        z-index:13;
    }
`;

const SidebarUl = styled.ul`
    margin-top: 1rem;
    text-align: center;
    padding-inline: 0;
`
const SidebarTextDiv = styled.div`
    word-break: keep-all;
    padding-block:0.5rem;
    &:hover {
        font-weight: bold;
    }
`