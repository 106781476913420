import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { CaptureContext } from './CaptureContext';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { SwitchDetectContext } from './SwitchDetectContext';

const VoiceControlContext = createContext();

const VoiceControlProvider = ({children}) => {
    const {
        voiceDetect, visualEffect
    } = useContext(SwitchDetectContext)
    const {
        cameraState, mode, 
        reCapture, setReCapture,
        messageShow, handleCaptureImg,canvasEffectRef,
        getVideoRef, turnOffVideoRef
    } = useContext(CaptureContext)
    const { t, i18n } = useTranslation();

    // const [voiceDetect, setVoiceDetect] = useState(false);// 提供給控制開關
    const [transcript, setTranscript] = useState('');
    const recognitionRef = useRef(null);
    const audioContextRef = useRef(null);
    const streamRef = useRef(null);
    const [voiceVolume, setVoiceVolume] = useState(0);


    // 偵測音量大小: 啟用
    const detectVolume = async() => { // AudioContext音量偵測無法先初始畫再手動執行，只能後續手動執行。
        if (isMobile) return;
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        await audioContext.audioWorklet.addModule(process.env.PUBLIC_URL + '/audioModule/volumeProcessor.js');
        audioContextRef.current = audioContext;
        try {
            const audioContext = audioContextRef.current;
            if (!audioContext) {
                console.error('AudioContext not initialized');
                return;
            }
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            streamRef.current = stream;
            const microphone = audioContext.createMediaStreamSource(stream);
            const volumeNode = new AudioWorkletNode(audioContext, 'volume-processor');
            if (volumeNode) {
                volumeNode.port.onmessage = (event) => {
                    const volume = (event.data * 7).toFixed(3);
                    setVoiceVolume(volume);
                };
            }
            microphone.connect(volumeNode).connect(audioContext.destination);
        } catch (error) {
            console.error('Error starting volume detection', error);
        }
    }

    // 偵測音量大小: 停用
    const stopDetection = () => {
        if (isMobile) return;
        if (audioContextRef.current && streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            audioContextRef.current.close();
        }
    };
    // useEffect(() => {
    //     console.log('voiceVolume', voiceVolume)
    // }, [voiceVolume])

    // 初始化錄音
    const iniRecording = async(i18n) => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

        if (!SpeechRecognition) {
            i18n.language === "zh"?  alert('您的瀏覽器不支持 Web Speech API') :  alert('Your browser does not support the Web Speech API');
            return;
        }
        const recognition = new SpeechRecognition();
        recognitionRef.current = recognition;
    
        recognition.continuous = true; // false 的話則是講完一段話停頓時就會停止辨識。如果開為true會導致需要額外重啟
        recognition.interimResults = true;// true 表示在講話的當下會即時辨識，不需要等待。
        if(i18n.language === "zh"){
            recognition.lang = 'zh-CN'; // 設置語言為中文
        } else {
            recognition.lang = 'en-US'; // 設置語言為英文
        }
        recognition.onresult = (event) => {
            let finalTranscript = '';
            for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                    finalTranscript += event.results[i][0].transcript;
                }
            }
            if (finalTranscript.endsWith('。') || finalTranscript.endsWith('.')) {// 除了chrome瀏覽器簡體及英文會有'。'和'，'結尾，需要移除才能判斷。
                finalTranscript = finalTranscript.slice(0, -1);
            }
            setTranscript(finalTranscript.trim().toLowerCase());
        };
        recognition.onerror = (event) => {
            console.error(event.error);
        };
    }

    //開始錄音
    const startRecording = () => {
        if (recognitionRef.current) {
            recognitionRef.current.start();
            recognitionRef.current.onend = () => {
                recognitionRef.current.start();
            };
        }
    };

    // 停止錄音
    const stopRecording = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            recognitionRef.current.onend = () => {
                recognitionRef.current.stop();
                return false;
            };
        }
    };


    // 朗讀文字 (暫時不啟用)
    const readTranscript = (text) => {
        if ('speechSynthesis' in window) {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.lang = 'zh-CN'; // 設置語言為中文
            // utterance.lang = 'en-US'; // 設置語言為英文
            window.speechSynthesis.speak(utterance);
        } else {
            i18n.language === "zh"?  alert('您的瀏覽器不支持 Speech Synthesis') :  alert('Your browser does not support the Speech Synthesis');
        }
    };

    const filterText = (input) => {
        let charsToRemove;
        if (i18n.language === "zh") {
            charsToRemove = ['延', '遲', '迟', '秒', '拍', '照'];
        } else {
            charsToRemove = ['delay', 'second'];
        }
        const regex = new RegExp(`[${charsToRemove.join('')}]`, 'g');
        const filteredText = input.replace(regex, '').trim();
        return filteredText;
    };

    const voiceTrigger = (i18n, cameraState, reCapture, transcript ) => {
        try {
            if (i18n.language === "zh") {
                if(transcript === '開啟鏡頭'||transcript === '开启镜头'||transcript === '开启尽头') {
                    if(!cameraState){
                        getVideoRef.current.click()
                    }
                } else if (transcript === '關閉鏡頭'||transcript === '关闭镜头'||transcript === '关闭尽头'){
                    if(cameraState) {
                        turnOffVideoRef.current.click()
                    }
                } else if(transcript === '拍照'){
                    if(!reCapture) {
                        handleCaptureImg(0, mode, visualEffect)
                    }
                } else if (transcript==='重拍'||transcript==='從拍'||transcript==='从拍') {
                    setReCapture(false)
                } else if (transcript.match(/延遲.+秒拍照/)||transcript.match(/延迟.+秒拍照/)) {
                    if(!reCapture) {
                        let delaySecond = typeof filterText(transcript) ==='number'? parseInt(filterText(transcript)): filterText(transcript);
                        // console.log(filterText(transcript))
                        if (delaySecond === '一') {
                            delaySecond = 1;
                        } else if ((delaySecond === '二') || (delaySecond === '兩')) {
                            delaySecond = 2;
                        } else if (delaySecond === '三') {
                            delaySecond = 3;
                        } else if (delaySecond === '四') {
                            delaySecond = 4;
                        } else if (delaySecond === '五') {
                            delaySecond = 5;
                        } else if (delaySecond === '六') {
                            delaySecond = 6;
                        } else if (delaySecond === '七') {
                            delaySecond = 7;
                        } else if (delaySecond === '八') {
                            delaySecond = 8;
                        } else if (delaySecond === '九') {
                            delaySecond = 9;
                        } else if (delaySecond === '十') {
                            delaySecond = 10;
                        }
                        if(delaySecond > 10 || delaySecond < 1) {
                            messageShow('.mediaArea', '延遲秒數不超過10秒', 'Delay seconds shall not exceed 10 seconds', 2, false);
                        }
                        if(delaySecond !== ''){
                            handleCaptureImg(delaySecond, mode, visualEffect)
                        }
                    }
                }
            } else {
                if(transcript === 'open lens' || transcript === 'open lance' || transcript === 'open this') {
                    // console.log('有嗎有嗎')
                    if(!cameraState){
                        getVideoRef.current.click()
                    }
                } else if (transcript === 'close lens' || transcript === 'close lance' || transcript === 'close this'){
                    if(cameraState) {
                        turnOffVideoRef.current.click()
                    }
                } else if(transcript === 'take picture' || transcript === 'take a picture' || transcript === 'take one picture'){
                    if(!reCapture) {
                        handleCaptureImg(0, mode, visualEffect)
                    }
                } else if (transcript==='again') {
                    setReCapture(false)
                } else if (transcript.match(/delay.+second/) || transcript.match(/delay.+nd/)) {
                    if(!reCapture) {
                        // let delaySecond = filterText(transcript);
                        let delaySecond = typeof filterText(transcript) ==='number'? parseInt(filterText(transcript)): filterText(transcript);
                        // console.log(filterText(transcript))
                        if (delaySecond === 'one') {
                            delaySecond = 1;
                        } else if (delaySecond === 'two'|| delaySecond === 'to'||delaySecond === '2') {
                            delaySecond = 2;
                        } else if (delaySecond === 'three') {
                            delaySecond = 3;
                        } else if (delaySecond === 'four') {
                            delaySecond = 4;
                        } else if (delaySecond === 'five') {
                            delaySecond = 5;
                        } else if (delaySecond === 'six') {
                            delaySecond = 6;
                        } else if (delaySecond === 'seven') {
                            delaySecond = 7;
                        } else if (delaySecond === 'eight') {
                            delaySecond = 8;
                        } else if (delaySecond === 'nine') {
                            delaySecond = 9;
                        } else if (delaySecond === 'ten') {
                            delaySecond = 10;
                        }
                        if(delaySecond > 10 || delaySecond < 1) {
                            messageShow('.mediaArea', '延遲秒數不超過10秒', 'Delay seconds shall not exceed 10 seconds', 2, false);
                        }
                        if(delaySecond !== ''){
                            handleCaptureImg(delaySecond, mode, visualEffect)
                        }
                        // console.log('指令有觸發嗎')
                    }
                }
            }
            
            setTranscript('')
            return;
        } catch (error) {
            console.log(error)
        
        }
    }

    // useEffect(() => {
    //     iniDetectVolume()
    // }, [])

    useEffect(() => {
        iniRecording(i18n)
    }, [i18n.language])

    useEffect(() => { // 下列順序必須由聲音轉文字最後執行，因為有些裝置不支援一起偵測，所以聲音轉文字是必要項目，必須最後且確保執行。
        if (voiceDetect) {
            detectVolume()// 音量偵測先執行
            startRecording()// 聲音轉文字再接著執行
        } else {
            stopDetection()// 音量停用先執行
            stopRecording()// 聲音轉文字停止再執行
        }
    }, [voiceDetect])

    useEffect(() => {
        // console.log(transcript)
        voiceTrigger(i18n, cameraState, reCapture, transcript)
    }, [i18n, transcript, cameraState, reCapture])

    return (
        <VoiceControlContext.Provider 
            value={{
                // voiceDetect, setVoiceDetect,
                transcript, startRecording, stopRecording,
                voiceVolume
            }}>
        {children}
        </VoiceControlContext.Provider>
    );
}
export { VoiceControlContext,  VoiceControlProvider}