import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconBtn } from '../../../styledComponents/btnStyle';
// import { VideoCaptureReducer, initialState } from '../../reducer/VideoCaptureReducer';
import { memo, useContext, useReducer } from 'react';
import { CaptureContext } from '../../../context/CaptureContext';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const LensesChange = () => {
    // const [streamState, dispatch] = useReducer(VideoCaptureReducer, initialState)
    const {
        mobileLens, setMobileLens,
        imgTaking,  getVideoStream, 
        turnOffVideoStream
    } = useContext(CaptureContext);
    const cameraLensesChange = () => {
        // console.log('mobileLens.LensState',mobileLens.LensState)
        let state = !mobileLens.LensState;
        // let state = false;
        Promise.resolve()
        .then(() => {
            setMobileLens(prev => ({...prev, LensState:state}));
        //     console.log('設置相機')
            return Promise.resolve()
        })
        .then(() => {
            turnOffVideoStream();
            // console.log('關閉相機')
            return Promise.resolve()
        })
        .then(() => {
            getVideoStream(null, state); // ''代替streamState.cameraState傳入，否則會關閉時無法清除串流，而不能執行。
            // console.log('開啟相機')
            return Promise.resolve()
        })
    }
    return (<>
        <LensesChangeBtnPC style={{visibility: imgTaking?'':'hidden'}}>
            <IconBtn>
                <FontAwesomeIcon icon={faArrowsRotate} onClick={cameraLensesChange} />
            </IconBtn> 
        </LensesChangeBtnPC>
        <LensesChangeBtnMobile style={{visibility: imgTaking?'':'hidden'}}>
            <IconBtn>
                <FontAwesomeIcon icon={faArrowsRotate} onClick={cameraLensesChange} />
            </IconBtn> 
        </LensesChangeBtnMobile>
    
    </>)
}

export default memo(LensesChange);

const LensesChangeBtnPC = styled.div`
    display: none;
    @media (min-width: 992px) {
        display: block;
        position: absolute;
        top: 2vh;
        left: 1rem;
        z-index: 11;
    }
`
const LensesChangeBtnMobile = styled.div`
    display: none;
    @media (max-width: 992px) {
        display: block;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(75%);
        z-index: 11;
    }
`


