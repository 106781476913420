import styled from 'styled-components';
import { faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { StyledIcon } from '../styledComponents/btnStyle';
import { useTranslation } from 'react-i18next';
import { ElementToggle } from '../components/function/ElementToggle';

const Declaration = ({ dataSet, data }) => {
    const [content, setContent]= useState('chunchen0118@gmail.com')
    const { t } = useTranslation();
    const renderContent = (content) => {
        return content.split('\br').map((line, index) => (
            <p style={{marginBottom:'0.5rem'}} key={index}>{line}</p>
        ));
    };
    const declarationRef = useRef(null) 
    // useEffect(() => { //暫時不啟用，因為複製mail會自動關閉
    //     if(declarationRef.current && data) {
    //         return ElementToggle(declarationRef, () => {
    //             setAnnounce(false)
    //         })
    //     }
    // }, [data])

    return (
        <Declarationmodal>
            <DeclarationArea ref={declarationRef}>
                <StyledIcon icon={faXmark} style={{right:'1rem', top:'1rem'}} onClick={() => dataSet(false)}/>
                <h3 style={{textAlign:'center',marginBottom:'1rem'}}>{t("declaration.announcement")}</h3>
                
                <Ptext style={{textAlign:'center'}}>
                    </Ptext>
                {/* <Ptext>{renderContent(t("declaration.content"))}</Ptext> */}
                <div style={{overflow:"hidden scroll"}}>
                    <table style={{width:'auto'}}>
                        <tbody>
                            <tr>
                                <TableTh>2024/8/15</TableTh>
                                <td>{t("declaration.content_7")}</td>
                            </tr>
                            <tr>
                                <TableTh>2024/7/10</TableTh>
                                <td>{t("declaration.content_6")}
                                    <br/>
                                    <small>{t("declaration.content_6-1")}</small>
                                </td>
                                {/* <td>36</td> */}
                            </tr>
                            <tr>
                                <TableTh>2024/7/4</TableTh>
                                <td>{t("declaration.content_5")}</td>
                                {/* <td>36</td> */}
                            </tr>
                            <tr>
                                <TableTh>2024/6/24</TableTh>
                                <td>{t("declaration.content_4")}</td>
                                {/* <td>36</td> */}
                            </tr>
                            <tr>
                                <TableTh>2024/6/6</TableTh>
                                <td>{t("declaration.content_3")}</td>
                                {/* <td>29</td> */}
                            </tr>
                            <tr>
                                <TableTh>2024/5/20</TableTh>
                                <td>{t("declaration.content_2")}</td>
                                {/* <td>45</td> */}
                            </tr>
                            <tr>
                                <TableTh>2024/5/3</TableTh>
                                <td>{t("declaration.content_1")}</td>
                                {/* <td>22</td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{width:'100%',marginTop:'auto',display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                    {/* 歡迎來信 */}
                    <p></p>
                    <span style={{marginInline:'0.3rem', fontSize:'medium', textAlign:'center', color:'#00bb99'}}>{renderContent(t("declaration.notice"))}</span>
                    <Popover 
                        content={<PopoverContentDiv>{content}</PopoverContentDiv>} 
                        title="" trigger="hover" 
                        overlayClassName="custom-popover">
                        <FontAwesomeIcon style={{fontSize:'x-large'}} icon={faEnvelope}/>
                    </Popover>
                </div>
            </DeclarationArea>
        </Declarationmodal>
        
    )
}

const Declarationmodal = styled.div.attrs({className:'Declarationmodal'})`
    position:absolute;
    top:0;
    left:0; 
    width:100%; 
    height:100%; 
    background-color:${props => props.theme.modalColor};
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px);
    z-index:14;
`

const DeclarationArea = styled.div.attrs({className:'Declaration'})`
    user-select:text;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 10px;
    background-color: ${props => props.theme.blurBg};
    display: flex;
    /* text-align: center; */
    flex-direction: column;
    font-size: larger;
    border: ${props => `1px solid ${props.theme.btnCaptureText}`};
    border-radius: 10px;
    width: 50vw;
    height: 70vh;
    z-index: 2222;
    @media (max-width: 992px) {
        width: 90vw;
        height: 80vh;
    }
`
const PopoverContentDiv = styled.div`
    font-size: clamp(1rem, 2vw, 1.1rem);
`

const Ptext = styled.p`
    font-size: clamp(1rem, 2vw, 1.1rem);
    margin-bottom: 0.5rem;
`
const TableTh = styled.th`
    align-content: start;
    text-align: start;
    padding-right: 1rem;
    width: 6rem;
`

export default Declaration;