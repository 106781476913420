export const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires= ${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
};

export const getCookie = (name) => {
    const cookie  = document.cookie;
    return cookie.replace(/\s+/g, '').split(";").reduce((acc, current) => {
        const [cookieName, value] = current.split('=');
        if(cookieName === name) {
            return value;
        }
        return acc;
    }, null)
};