import styled from 'styled-components'

export const AppContent = styled.div`
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.textColor};
    transition: all 0.5s;
    a {
        color: ${props => props.theme.linkColor};
        color: #71a3dc;
        text-decoration: none;
    }
    button {
        color: ${props => props.theme.textColor};
        background: ${props => props.theme.btnBgColor};
    }
`