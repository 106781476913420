import React, { memo, useContext, useEffect, useRef, useState } from 'react';
// import Range from '@atlaskit/range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { CanvasEffectContext } from '../../context/CanvasEffectContext';

const RangeControlled = ({color='#00967b', min=0, max=100, value, setFn,  elementRef, animationFrame=false}) => {
	// const [value, setFn] = useState(50);
    
    // 滾輪控制
    useEffect(() => {
        const handleWheel = (event) => {
            setFn(() => {
                // console.log('prev', prev)
                const increment = -Math.sign(event.deltaY); // 滾動方向判斷
                const newValue = Number(value) + increment * (max-min)/100; // Number一定要加入數字型別轉換，否則點擊和wheel調整數值間會有問題
                return Math.min(Math.max( Math.ceil(newValue), min), max);
            });
        };
        if(elementRef.current) {
            elementRef.current.addEventListener('wheel', handleWheel);
        }

        return () => {
            if(elementRef.current) {
                elementRef.current.removeEventListener('wheel', handleWheel);
            }
        };
    }, [value, elementRef.current, min, max])

    // 手機拖曳修正
    useEffect(() => {
        const handleTouchStart = (event) => {
            event.preventDefault();
            const touch = event.touches[0];
            elementRef.current.startX = touch.clientX;
            elementRef.current.startValue = Number(value);
        };
    
        const handleTouchMove = (event) => {
            const touch = event.touches[0];
            const deltaX = touch.clientX - elementRef.current.startX ;
            const newValue = Number(elementRef.current.startValue) + deltaX / elementRef.current.clientWidth * (max-min);
            setFn(Math.min(Math.max(Math.round(newValue), min), max));
        };

        const handleTouchEnd = (event) => {
            const touch = event.changedTouches[0];
            const deltaX = touch.clientX - elementRef.current.startX;

            if (Math.abs(deltaX) < 5) {
                const rect = elementRef.current.getBoundingClientRect();
                const clickX = touch.clientX - rect.left;
                const clickValue = (clickX / Number(elementRef.current.clientWidth)) * (max-min);
                // console.log('Math.round(clickValue', Math.round(clickValue));
                setFn(Math.min(Math.max(Math.round(clickValue), min), max));
            }
        };
        if(elementRef.current) {
            elementRef.current.addEventListener('touchstart', handleTouchStart);
            elementRef.current.addEventListener('touchmove', handleTouchMove);
            elementRef.current.addEventListener('touchend', handleTouchEnd);
        }

        return () => {
            if(elementRef.current) {
                elementRef.current.removeEventListener('touchstart', handleTouchStart);
                elementRef.current.removeEventListener('touchmove', handleTouchMove);
                elementRef.current.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [elementRef.current, value]);

	return (
		<div style={{display:'flex',alignItems:'center', paddingBlock:'0.75rem'}}>
            {/* <FontAwesomeIcon icon={faRotateLeft} style={{cursor:'pointer'}} onClick={() => setFn(50)}/> */}
            <div style={{display:'flex', marginInline:'0.5rem'}}>
                {/* <Range
                    aria-label="controlled range"
                    step={1}
                    value={value}
                    onChange={(value) => setFn(value)}
                /> */}
                <CustomRange ref={elementRef}
                    min={min}
                    max={max}
                    color={color}
                    type="range"
                    value={value}
                    onChange={(e) =>{ 
                        if (animationFrame){ // 直接手動調整會因色調數值跳動過大而過度渲染色調
                            const newValue = parseInt(e.target.value);
                            setFn((prevValue) => {
                                const diff = newValue - prevValue;// 變動的總值
                                const steps = 2; // 將值變為N步進行 過高會影響web拖曳動畫
                                const increment = diff / steps;
                                let currentStep = 0;
                                const smoothChange = () => {
                                    if (currentStep < steps) {
                                        setFn(prev => prev + increment);
                                        currentStep++;
                                        requestAnimationFrame(smoothChange); // 使用 requestAnimationFrame 逐幀過度
                                    } else {
                                        setFn(newValue); 
                                    }
                                };
                                smoothChange();
                            });
                        } else {
                            setFn(e.target.value) // 如果是調整色調這種不能直接使用，其他可以
                        }
                    }} 
                    // onChange={(e) => {
                    //     const newValue = parseInt(e.target.value);
                    //     setFn((prevValue) => {
                    //         const diff = newValue - prevValue;// 變動的總值
                    //         const steps = 2; // 將值變為N步進行 過高會影響web拖曳動畫
                    //         const increment = diff / steps;
                    //         let currentStep = 0;
                    //         const smoothChange = () => {
                    //             if (currentStep < steps) {
                    //                 setFn(prev => prev + increment);
                    //                 currentStep++;
                    //                 requestAnimationFrame(smoothChange); // 使用 requestAnimationFrame 逐幀過度
                    //             } else {
                    //                 setFn(newValue); 
                    //             }
                    //         };
                    //         smoothChange();
                    //     });
                    // }}
                />
            </div>
            {/* <span style={{width:'2rem'}}>{value}</span> */}
        </div>
	);
};

const CustomRange = styled.input.attrs({className: 'CustomRange'})`
    width: 100%;
    height: 2px;
    accent-color: ${props => props.color};// 操控小圓球顏色
`;


export default memo(RangeControlled);