import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RotateLoader } from 'react-spinners';

const Loading = () => {
    const [isLoading, setIsLoading] = useState(true);
    const handleLoad = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    };

    useEffect(() => {
        if (document.readyState === 'complete') {// 檢查當前的文檔加載狀態
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
            document.addEventListener('DOMContentLoaded', handleLoad);// 手機要額外監聽DOMContentLoaded才能正常監聽
        }
    
        return () => {
            window.removeEventListener('load', handleLoad);
            document.removeEventListener('DOMContentLoaded', handleLoad);
        };
    }, []);

    return(<>
        { isLoading &&
            <LoadingDiv>
                <div style={{margin:'auto'}}>
                    <RotateLoader
                        color="#00c9a5" 
                        loading={true} 
                        size={15} 
                    />
                </div>
            </LoadingDiv>
        }
    
    </>)
}

export default Loading;

const LoadingDiv = styled.div.attrs({className:'Loading'})`
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #000000a1;
    backdrop-filter:blur(5px);
    z-index: 9999;
`

