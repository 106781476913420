/*切換顯示的元件自動縮合*/
export const ElementToggle = (Ref, handleSwitch) => {
    const showToggle = (e) => {
        let clickedInsideRef = false;
        if(Array.isArray(Ref)){
            Ref.forEach(ref => {
                if (ref.current && ref.current.contains(e.target)) {
                    clickedInsideRef = true;
                }
            });
        } else {
            if (Ref.current && Ref.current.contains(e.target)) {
                clickedInsideRef = true;
            }
        }
        if (!clickedInsideRef) {
            handleSwitch();
        }
    };

    document.addEventListener('click', showToggle);

    return () => {
        document.removeEventListener('click', showToggle);
    };
}