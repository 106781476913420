import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { setCookie, getCookie } from './SetCookie';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };
    
    useEffect(() => {
        const languague = getCookie('language');
        changeLanguage(languague)
    }, [])

    return (
        <div style={{position:'relative'}}>
            {i18n.language === 'zh'?
                    <SwitcherBtn className='textHover' onClick={() => {
                        changeLanguage('en')
                        setCookie('language', 'en', 180)
                        }}>English
                    </SwitcherBtn>:
                    <SwitcherBtn className='textHover' onClick={() => {
                        changeLanguage('zh')
                        setCookie('language', 'zh', 180)
                        }}>中文
                    </SwitcherBtn>
                    
                }
        </div>
    );
};

export default LanguageSwitcher;

const SwitcherBtn = styled.div`
    text-align: center;
    position: relative;
    background-color: transparent;
    border: 0;
    margin-left:1rem;
    border-radius: 5px;
    padding-inline: 5px;
    padding-block: 10px;
    cursor: pointer;
`;

const SwitcherMenu = styled.div`
    position: absolute;
    display: grid;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
`;


const SwitcherMenuBtn = styled.span`
    /* color: black; */
    text-align: center;
    padding-block: 0.5rem;
    width: 6rem;
    height: 1.5rem;
    line-height: 1.5rem;
    cursor: pointer;
    
    &:hover {
        font-weight: bold;
    }
    
`;