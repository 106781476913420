import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    state:false
}

const SidebarSwitchSlice = createSlice({
    name: 'sidebarSwitch',
    initialState,
    reducers: {
        sidebarSwitchFn: (state, action) => {
            switch (action.payload) {
                case 'switch':
                    return {state:!state.state}
                default:
                    return state;
            }
        }
    }
})

export const {sidebarSwitchFn} = SidebarSwitchSlice.actions;
export const shareSidebarValue = state => state.sidebarSwitch;
export default SidebarSwitchSlice.reducer;