import React, { useState, useContext, useEffect, useRef } from 'react';
import { CaptureContext } from '../../context/CaptureContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ElementToggle } from './ElementToggle';

const CustomSelect = ({ options, selected, onChange }) => {
    const {
        cameraState, mobileLens, 
        imgQuality, setImgQuality,
        getVideoStream, turnOffVideoStream
        
    } = useContext(CaptureContext);
    const [isOpen, setIsOpen] = useState(false);
    const selectOptionsRef = useRef(null);
    const handleSelect = (value) => {
        onChange(value);
        setIsOpen(false);
        if(imgQuality !== 'general'){ // 畫質都切換回一般，因為使用特殊模式使用不同畫質會有尺寸問題待修復
            setImgQuality('general')
        }
        if(cameraState){ // 切換模式要重啟相機
            Promise.resolve()
            .then(() => {
                turnOffVideoStream()
                return Promise.resolve();
            })
            .then(() => {
                getVideoStream(null, mobileLens.LensState);
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error turning off video stream:", error);
            });
        }
    };
    useEffect(() => {
        if(selectOptionsRef.current && isOpen) {
            return ElementToggle(selectOptionsRef, () => {
                setIsOpen(false)
            })
        }
    }, [isOpen])

    return (
        <SelectContainer>
            <SelectDisplay onClick={() => setTimeout(() => {setIsOpen(!isOpen)}, 0)}>
                {options.find(option => option.value === selected)?.label || 'Select...'}
            </SelectDisplay>
            {isOpen && (
                <SelectOptions ref={selectOptionsRef}>
                    {options.map(option => (
                        <SelectOption
                            key={option.value}
                            onClick={() => handleSelect(option.value)}
                        >
                            {option.label}
                        </SelectOption>
                    ))}
                </SelectOptions>
            )}
        </SelectContainer>
    );
};

const ModePicker = () => {
    const { mode, setMode } = useContext(CaptureContext);
    const { t, i18n } = useTranslation();
    const handleOnChange = (value) => {
        setMode(value);
    };

    const options = [
        { label: t("videoCapture.modeGeneral"), value: 'general' },
        { label: t("videoCapture.modeStraight"), value: 'straight' },
        { label: t("videoCapture.modeHorizon"), value: 'horizon' },
    ];
    

    return (
        <div style={{ margin: 'auto', marginBlock:'0.5rem'}}>
            {/* <label htmlFor="" style={{ display: 'block' }}>模式</label> */}
            <CustomSelect
                options={options}
                selected={mode}
                onChange={handleOnChange}
            />
        </div>
    );
};

export default ModePicker;

const SelectContainer = styled.div`
    position: relative;
    max-width: 100px;
    width: auto;
    margin: auto;
`;

const SelectDisplay = styled.div`
    border:${props =>`1px solid ${props.theme.border}`};
    background: #deb88700;
    border-radius: 2rem;
    font-size: medium;
    padding-block: 0.2rem;
    padding-inline: 1rem;
    cursor: pointer;
    text-align: center;
    &:hover {
        background-color: #ffffff2e;
    }
`;

const SelectOptions = styled.div` // 選單
    position: absolute;
    bottom: 120%;
    left: 0;
    right: 0;
    font-size: medium;
    background: ${props =>`${props.theme.blurBg}`};
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    color: ${props =>`${props.theme.textColor}`};
`;

const SelectOption = styled.div`
    padding-inline: 0.5rem;
    padding-block: 0.5rem;
    cursor: pointer;
    &:hover {
        background-color: #9b9b9b;
    }
`;