import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import '../../css/CarouselSlick.css';
import '../../../css/CarouselSlick.css'
import { memo, useContext, useEffect, useRef } from 'react';
import { CaptureContext } from '../../../context/CaptureContext';

const CarouselSlick = () => {
    const {
        capturedImg, chooseImg, setChooseImg,
    } = useContext(CaptureContext);
    const sliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: chooseImg,// 加上初始顯示照片index才不會有從第一張切換的抖動
        // autoplay: true,
        afterChange: (index) => {
            // setChooseImg(index)
            return setChooseImg(index)
        },
        
        
    };

    const goToSlide = (index) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };

    useEffect(() => {
        goToSlide(chooseImg)
    }, [chooseImg])

    return (        
        <CarouseAreaDiv>
            <Slider ref={sliderRef} {...settings}>
                { capturedImg.map((item, index) => (
                    <Wrap key={`CaroulImg`+index} >
                        <CarouseImg src={item} alt='' />
                    </Wrap>
                ))}
            </Slider>
        </CarouseAreaDiv>
    )
}
export default memo(CarouselSlick);


const CarouseAreaDiv = styled.div.attrs({className:'CarouseAreaDiv'})`
    width: 100%;
    height: 100%;
    position: relative;
`

const Wrap = styled.div.attrs({className:'Wrap'})`
    display: flex !important;
    align-items: center;
    height: 100%;
    /* width: 300px; */
    outline: none;
`

const CarouseImg = styled.img.attrs({className:'CarouseImg'})`
    /* margin: auto; */
    transition-duration: 300ms;
    max-width: 100%;

    &:hover {
        /* border: 4px solid rgba(249, 249, 249, 0.8); */
    }
`